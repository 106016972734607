import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { SearchSchemaInput } from '@tanstack/react-router';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useDefaultJurisdiction } from 'common/hooks/useDefaultJurisdiction';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';

import type { ApiDocument } from '@legalfly/api/documents';
import type { ApiPlaybook } from '@legalfly/api/playbooks';
import { useSelectedDocuments } from '@legalfly/components/documentPicker/SelectedDocumentsProvider';
import { withSelectedDocuments } from '@legalfly/components/documentPicker/withSelectedDocuments';
import {
  DocumentsUploadProgress,
  useDocumentsUploadProgress,
} from '@legalfly/components/documentsUploadProgress';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, UploadButton } from '@legalfly/ui/button';
import { SearchInputForm } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { ContentDropZone } from 'components/common/content/ContentDropZone';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { DropZone } from 'components/common/upload/DropZone';
import CreateFolderDialog from 'components/documents/createFolder/CreateFolderDialog';
import { DocumentPicker } from 'components/documents/documentPicker/DocumentPicker';
import { EditableFolderName } from 'components/documents/EditableFolderName';
import { MultiReviewSelectPlaybooks } from 'components/multi-reviews/start/MultiReviewSelectPlaybooks';
import {
  documentsQueryOptions,
  documentsToasts,
  ListenToDocumentsUploadStatus,
  useDropDocuments,
  useFolder,
} from 'core/modules/documents';
import { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';
import { multiReviewToasts, useCreateMultiReview } from 'core/modules/multi-reviews';
import { mapStringToPlaybookItems } from 'core/modules/playbooks/mapStringToPlaybookItems';

export const Route = createFileRoute('/_auth/_layout/multi-review/start/')({
  validateSearch: (search: { documentUuids?: string[] } & SearchSchemaInput) => {
    return {
      documentUuids: search?.documentUuids ?? [],
    };
  },
  component: withSelectedDocuments(MultiReviewStartRoute),
  loader({ context }) {
    return context.queryClient.ensureQueryData(documentsQueryOptions.folder());
  },
});

function MultiReviewStartRoute() {
  const { documentUuids } = Route.useSearch();

  return <MultiReviewStart documentUuids={documentUuids} />;
}

export function MultiReviewStart({
  uuid,
  documentUuids,
}: {
  uuid?: string;
  documentUuids: string[];
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const defaultJurisdiction = useDefaultJurisdiction();
  const { documents, folder } = useFolder({ uuid });
  const { dropDocuments } = useDropDocuments();
  const { selectedDocuments, addSelectedDocuments } = useSelectedDocuments();
  const { filteredItems: filteredDocuments, setKeyword } = useKeywordFilter(documents, 'name');

  const { createFiles, isCreatingFiles, numberOfFilesToUpload } = useCreateFiles('multi-review');
  const { createMultiReview, isLoading: isCreatingMultiReview } = useCreateMultiReview();
  const documentUploadProgress = useDocumentsUploadProgress(documents, numberOfFilesToUpload);

  useEffect(() => {
    if (documentUuids.length && documents.length) {
      addSelectedDocuments(documentUuids.map((uuid) => documents.find((d) => d.uuid === uuid)!));
    }
  }, [addSelectedDocuments, documents, documentUuids]);

  const uploadFiles = async (files: File[]) => {
    const results = await createFiles({ uuid, files });

    if (results.length) {
      addSelectedDocuments(results);
    }
  };

  const handleBackPress = () => {
    if (folder?.parent) {
      return navigate({
        to: '/multi-review/start/$uuid',
        params: { uuid: folder.parent.uuid },
      });
    }

    return navigate({
      to: '/multi-review/start',
    });
  };

  const handleStartMultiReview = async ({
    playbooks,
    virtualPlaybookItems,
  }: {
    playbooks: ApiPlaybook[];
    virtualPlaybookItems: string;
  }) => {
    trackEvent({
      action: 'submit',
      category: 'multiReviewStart',
    });

    const items = mapStringToPlaybookItems(virtualPlaybookItems);

    const multiReview = await withToasts(
      createMultiReview({
        body: {
          playbookUuids: playbooks.map((p) => p.uuid),
          documentUuids: selectedDocuments.map((d) => d.uuid),
          jurisdiction: defaultJurisdiction,
          language: i18n.language,
          virtualPlaybookItems: items,
        },
      }),
    )(multiReviewToasts.createMultiReview());

    navigate({
      to: '/multi-review/$uuid',
      params: { uuid: multiReview.uuid },
    });
  };

  const handleSelectFolder = (document: ApiDocument) => {
    navigate({
      to: '/multi-review/start/$uuid',
      params: { uuid: document.uuid },
    });
  };

  const handleDropDocuments = (params: { sourceUuids: string[]; targetUuid: string }) => {
    withToasts(dropDocuments({ body: { uuid: folder?.uuid, ...params } }))(
      documentsToasts.dropDocuments(),
    );
  };

  const hasSelectedDocuments = selectedDocuments.length > 0;
  const hasDocuments = documents.length > 0;
  const canGoBack = Boolean(documents[0]?.parent) || Boolean(!hasDocuments && uuid);

  return (
    <div className='flex gap-3'>
      <div className='flex-2'>
        <ContentDropZone
          onDropFiles={uploadFiles}
          disabled={!hasDocuments}
          parentClassName={cn(
            !documentUploadProgress.isUploading ? 'h-content' : 'h-content-with-progress',
          )}
        >
          <ContentHeader>
            <ContentHeader.Title>
              {folder ? (
                <EditableFolderName uuid={folder.uuid} name={folder.name} />
              ) : (
                t('multiReview.startTitle')
              )}
            </ContentHeader.Title>
            <ContentHeader.SubTitle>{t('multiReview.subtitle')}</ContentHeader.SubTitle>
            <ContentHeader.Actions>
              {canGoBack && (
                <Button onClick={handleBackPress} variant='soft'>
                  {t('action.back')}
                </Button>
              )}
              <SearchInputForm
                onKeywordChange={setKeyword}
                onBlur={() =>
                  trackEvent({
                    action: 'blur',
                    category: 'multiReviewStart',
                    label: 'search',
                  })
                }
              />
              <UploadButton
                onUpload={uploadFiles}
                renderLeft={<Icon name='upload-1' />}
                disabled={isCreatingFiles}
              >
                {t('action.uploadOrDrop')}
              </UploadButton>
              <CreateFolderDialog uuid={uuid} />
            </ContentHeader.Actions>
          </ContentHeader>

          {hasDocuments ? (
            <div className='mb-4 h-full'>
              <ListenToDocumentsUploadStatus documents={documents} />

              <DocumentPicker
                documents={filteredDocuments}
                onSelectFolder={handleSelectFolder}
                onDropDocuments={handleDropDocuments}
                allowFolderSelection
              />
            </div>
          ) : (
            <DropZone onDropFiles={uploadFiles} withBackground />
          )}
        </ContentDropZone>
        <DocumentsUploadProgress {...documentUploadProgress} />
      </div>
      <MultiReviewSelectPlaybooks>
        {({ playbooks, virtualPlaybookItems }) => (
          <Button
            renderLeft={<Icon name='check' />}
            disabled={!hasSelectedDocuments || (playbooks.length === 0 && !virtualPlaybookItems)}
            isLoading={isCreatingMultiReview}
            onClick={() => handleStartMultiReview({ playbooks, virtualPlaybookItems })}
            className='self-start'
          >
            {t('action.start')}
          </Button>
        )}
      </MultiReviewSelectPlaybooks>
    </div>
  );
}
