/* eslint-disable jsx-a11y/label-has-associated-control */
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CellContext, ColumnDef } from '@tanstack/react-table';

import { type ApiDocument } from '@legalfly/api/documents';
import type { UseColumnsProps } from '@legalfly/components/documentPicker/BaseDocumentPicker';
import { NameColumn } from '@legalfly/components/documentPicker/columns/name';
import { SelectColumn } from '@legalfly/components/documentPicker/columns/select';
import { SortableTableHeader } from '@legalfly/components/table/SortableTableHeader';
import { UserAvatar } from '@legalfly/components/users/UserAvatar';
import { ContextMenu, ContextMenuTrigger } from '@legalfly/ui/contextMenu';
import { TableCellButton } from '@legalfly/ui/tableCellButton';
import { formatRelativeTime } from '@legalfly/utils/date';
import { TABLE_COLUMN_SIZES } from 'components/review/constants';
import { useRetryFile } from 'core/modules/documents';

import { DocumentsContextMenu } from '../contextMenu/DocumentsContextMenu';
import { DocumentPickerActionPopover } from './DocumentPickerActionPopover';

type SharedCellProps = Pick<
  UseColumnsProps,
  'allowContextMenu' | 'onDocumentClick' | 'onDocumentDoubleClick'
>;

const CellWrapper = ({
  table,
  row,
  children,
  allowContextMenu,
  onDocumentClick,
  onDocumentDoubleClick,
  tooltipText,
}: CellContext<ApiDocument, unknown> & { children: ReactNode } & SharedCellProps & {
    tooltipText?: string;
  }) => {
  const document = row.original;
  const selectedDocuments = table.getSelectedRowModel().rows.map((r) => r.original);
  const canSelect = row.getCanSelect();
  const disabled = !allowContextMenu || !canSelect;

  const handleClick = (e: React.MouseEvent) => {
    if (
      e.target instanceof Element &&
      (e.target.closest('[role="checkbox"]') || e.target.closest('label'))
    ) {
      return;
    }

    switch (e.detail) {
      case 0:
      case 1:
        onDocumentClick(document);
        break;
      case 2:
        onDocumentDoubleClick?.(document);
        break;
    }
  };

  return (
    <ContextMenu>
      <ContextMenuTrigger asChild disabled={disabled}>
        <TableCellButton
          tooltipText={tooltipText}
          className='flex h-[52px] items-center overflow-hidden whitespace-nowrap p-0 px-5 focus-visible:outline focus-visible:outline-1 focus-visible:-outline-offset-8 focus-visible:outline-stroke-weak [&:has([role=checkbox])]:pr-0'
          onContextMenu={() => {
            if (disabled) {
              return;
            }
            if (!selectedDocuments.some((d) => d.uuid === document.uuid)) {
              table.toggleAllRowsSelected(false);
              setTimeout(() => {
                row.toggleSelected(true);
              }, 0);
            }
          }}
          onClick={handleClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.currentTarget.click();
            }
          }}
          role='row'
          tabIndex={0}
        >
          {children}
        </TableCellButton>
      </ContextMenuTrigger>
      <DocumentsContextMenu document={document} selectedDocuments={selectedDocuments} />
    </ContextMenu>
  );
};

export const useDocumentPickerColumns = ({
  onDocumentClick,
  onDocumentDoubleClick,
  allowMultiple,
  allowSelection,
  allowContextMenu,
  allowDelete,
  allowFavorite,
}: UseColumnsProps) => {
  const { t, i18n } = useTranslation();
  const { retryFile } = useRetryFile();

  const columns = useMemo(() => {
    const sharedCellProps: SharedCellProps = {
      allowContextMenu,
      onDocumentClick,
      onDocumentDoubleClick,
    };

    const columnDefs: ColumnDef<ApiDocument>[] = [
      {
        id: 'select',
        size: TABLE_COLUMN_SIZES.SELECT,
        header: (context) => (
          <SelectColumn.Header allowMultiple={allowMultiple} table={context.table} />
        ),
        cell: (props) => {
          return <SelectColumn.Cell {...props} />;
        },
      },
      {
        id: 'name',
        accessorKey: 'name',
        maxSize: TABLE_COLUMN_SIZES.NAME,
        enableSorting: true,
        header: (context) => (
          <SortableTableHeader column={context.column}>
            {t('review.table.name')}
          </SortableTableHeader>
        ),
        cell: (props) => {
          const document = props.row.original;
          return (
            <CellWrapper {...props} {...sharedCellProps} tooltipText={props.row.getValue('name')}>
              <NameColumn.CellContent document={document} onRetryFile={retryFile} />
            </CellWrapper>
          );
        },
      },
      {
        id: 'owner',
        size: TABLE_COLUMN_SIZES.OWNER,
        accessorKey: 'owner.firstName',
        enableSorting: true,
        header: (context) => (
          <SortableTableHeader column={context.column}>
            {t('review.table.owner')}
          </SortableTableHeader>
        ),
        cell: (props) => {
          const document = props.row.original;
          return (
            <CellWrapper {...props} {...sharedCellProps}>
              {document.owner && <UserAvatar size='sm' {...document.owner} />}
            </CellWrapper>
          );
        },
      },
      {
        id: 'createdAt',
        size: 150,
        accessorKey: 'createdAt',
        enableSorting: true,
        header: (context) => (
          <SortableTableHeader column={context.column}>
            {t('review.table.createdAt')}
          </SortableTableHeader>
        ),
        cell: (props) => {
          const document = props.row.original;
          return (
            <CellWrapper
              {...props}
              {...sharedCellProps}
              tooltipText={formatRelativeTime(document.createdAt, i18n.language)}
            >
              {formatRelativeTime(document.createdAt, i18n.language)}
            </CellWrapper>
          );
        },
      },
      ...(allowFavorite || allowDelete
        ? [
            {
              id: 'actions',
              size: 120,
              cell: (props: CellContext<ApiDocument, unknown>) => {
                const document = props.row.original;
                return (
                  <DocumentPickerActionPopover
                    document={document}
                    allowDelete={allowDelete}
                    allowFavorite={allowFavorite}
                  />
                );
              },
            },
          ]
        : []),
    ];

    if (!allowSelection) {
      return columnDefs.filter((column) => column.id !== 'select');
    }

    return columnDefs;
  }, [
    allowSelection,
    allowMultiple,
    t,
    allowContextMenu,
    retryFile,
    onDocumentClick,
    onDocumentDoubleClick,
    i18n.language,
    allowDelete,
    allowFavorite,
  ]);

  return columns;
};
