import { type ApiDocument } from '@legalfly/api/documents';
import { FavoriteTypeEnum } from '@legalfly/api/favorites';
import { FavoriteButton } from 'components/common/button/FavoriteButton';

import { DocumentListMenu } from '../DocumentListMenu';

interface DocumentPickerActionPopoverProps {
  document: ApiDocument;
  allowDelete: boolean;
  allowFavorite: boolean;
}

export const DocumentPickerActionPopover = ({
  document,
  allowDelete,
  allowFavorite,
}: DocumentPickerActionPopoverProps) => {
  return (
    <div className='flex items-center justify-end gap-2 px-5'>
      {allowFavorite && (
        <FavoriteButton
          className='opacity-0 transition-opacity duration-500 focus:opacity-100 group-hover:opacity-100'
          entityType={FavoriteTypeEnum.DOCUMENT}
          entity={document}
        />
      )}
      <DocumentListMenu
        document={document}
        allowDelete={allowDelete}
        allowFavorite={allowFavorite}
      />
    </div>
  );
};
