import { type ComponentProps, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Jurisdiction } from '@legalfly/api/core';
import {
  Combobox,
  ComboboxContent,
  ComboboxListItem,
  ComboboxTrigger,
} from '@legalfly/ui/combobox';
import { Icon } from '@legalfly/ui/icon';
import type { SelectContent, SelectTrigger } from '@legalfly/ui/select';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

type Props = {
  value: Jurisdiction | undefined;
  onChange: (v: Jurisdiction) => void;
  triggerProps?: ComponentProps<typeof SelectTrigger>;
  textClassName?: string;
  contentProps?: ComponentProps<typeof SelectContent>;
};

export const JurisdictionIcon = ({ jurisdiction }: { jurisdiction: Jurisdiction }) => {
  return <Icon name={jurisdiction === Jurisdiction.NONE ? 'globe' : `flag-${jurisdiction}`} />;
};

export const SelectJurisdiction = ({
  value,
  onChange,
  triggerProps,
  contentProps,
  textClassName,
}: Props) => {
  const { t } = useTranslation('components');

  const values = useMemo(() => {
    return Object.values(Jurisdiction)
      .sort((a, b) => {
        if (a === Jurisdiction.NONE) return -1;
        if (b === Jurisdiction.NONE) return 1;
        return t(`config.country.${a}`).localeCompare(t(`config.country.${b}`));
      })
      .map((key) => ({ key, label: t(`config.country.${key}`) }));
  }, [t]);

  const renderItem = useCallback((item: { key: Jurisdiction; label: string }) => {
    return (
      <ComboboxListItem key={item.key} value={item.key}>
        <div className='flex items-center gap-2'>
          <JurisdictionIcon jurisdiction={item.key} />
          <Text variant='body'>{item.label}</Text>
        </div>
      </ComboboxListItem>
    );
  }, []);

  return (
    <Combobox value={value} onChange={onChange}>
      <ComboboxTrigger {...triggerProps} className={cn(triggerProps?.className, 'w-fit gap-2')}>
        <JurisdictionIcon jurisdiction={value ?? Jurisdiction.NONE} />
        {value && (
          <Text variant='body' className={textClassName}>{`${t(`config.country.${value}`)}`}</Text>
        )}
      </ComboboxTrigger>
      <ComboboxContent
        displayKey='label'
        items={values}
        renderItem={renderItem}
        {...contentProps}
      />
    </Combobox>
  );
};
