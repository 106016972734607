import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher';

const api = legalFlyConfig.api.paths;

export const createTranslateApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const translateText = (params: { body: BodyOf<'translate/text/post'>; signal?: AbortSignal }) => {
    return client(api.translate.text, {
      method: 'POST',
      body: params.body,
      signal: params.signal,
    });
  };

  const translateFiles = (params: { body: BodyOf<'translate/files/post'> }) => {
    return client(api.translate.files, {
      method: 'POST',
      body: params.body,
    });
  };

  const getTranslateGroup = (params: { params: ParamsOf<'translate/group/:uuid/get'> }) => {
    return client(api.translate.group.get, {
      method: 'GET',
      params: params.params,
    });
  };

  const downloadTranslateFile = (params: {
    params: ParamsOf<'translate/files/:uuid/download/post'>;
  }) => {
    return client(api.translate.file.download, {
      method: 'POST',
      params: params.params,
    });
  };

  return {
    translateText,
    translateFiles,
    getTranslateGroup,
    downloadTranslateFile,
  };
};

export type TranslateApi = ReturnType<typeof createTranslateApi>;
export type ApiTranslateGroup = ResponseOf<'translate/group/:uuid/get'>;
export type ApiTranslateFile = ApiTranslateGroup['files'][number];
