import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, QueryOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher';
import type { UnwrapAsyncGenerator } from '../fetcher/types';

export const createDraftingApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getDraftingTemplates = (query: QueryOf<'templates/get'>) => {
    return client(api.drafting.templates.list, { method: 'GET', query });
  };

  const getDraftingTemplate = ({ uuid }: ParamsOf<'templates/:uuid/get'>) => {
    return client(api.drafting.templates.detail, { method: 'GET', params: { uuid } });
  };

  const createDraftingTemplate = ({ body }: { body: BodyOf<'templates/post'> }) => {
    return client(api.drafting.templates.create, { method: 'POST', body });
  };

  const updateDraftingTemplate = ({
    uuid,
    body,
  }: {
    uuid: string;
    body: BodyOf<'templates/:uuid/patch'>;
  }) => {
    return client(api.drafting.templates.update, { method: 'PATCH', params: { uuid }, body });
  };

  const deleteDraftingTemplate = ({ uuid }: ParamsOf<'templates/:uuid/delete'>) => {
    return client(api.drafting.templates.delete, { method: 'DELETE', params: { uuid } });
  };

  const getDrafts = (query: QueryOf<'drafts/get'>) => {
    return client(api.drafting.drafts.list, { method: 'GET', query });
  };

  const getDraft = ({ uuid }: ParamsOf<'drafts/:uuid/get'>) => {
    return client(api.drafting.drafts.detail, { method: 'GET', params: { uuid } });
  };

  const createDraft = ({ body }: { body: BodyOf<'drafts/post'> }) => {
    return client(api.drafting.drafts.create, { method: 'POST', body });
  };

  const updateDraft = ({ uuid, body }: { uuid: string; body: BodyOf<'drafts/:uuid/patch'> }) => {
    return client(api.drafting.drafts.update, { method: 'PATCH', params: { uuid }, body });
  };

  const deleteDraft = ({ uuid }: ParamsOf<'drafts/:uuid/delete'>) => {
    return client(api.drafting.drafts.delete, { method: 'DELETE', params: { uuid } });
  };

  const downloadDraftAsPdf = async ({ uuid }: ParamsOf<'drafts/:uuid/pdf/get'>) => {
    const res = await client(api.drafting.drafts.download.pdf, { method: 'GET', params: { uuid } });
    // the fetcher will read the stream and unwrap the response
    return res as unknown as UnwrapAsyncGenerator<typeof res>;
  };

  const downloadDraftAsDocx = async ({ uuid }: ParamsOf<'drafts/:uuid/docx/get'>) => {
    const res = await client(api.drafting.drafts.download.docx, {
      method: 'GET',
      params: { uuid },
    });
    // the fetcher will read the stream and unwrap the response
    return res as unknown as UnwrapAsyncGenerator<typeof res>;
  };

  const createTemplateClause = ({
    uuid,
    body,
  }: {
    uuid: ParamsOf<'templates/:uuid/clauses/post'>['uuid'];
    body: BodyOf<'templates/:uuid/clauses/post'>;
  }) => {
    return client(api.drafting.templates.clauses.create, {
      method: 'POST',
      body,
      params: { uuid },
    });
  };

  const updateTemplateClause = ({
    uuid,
    clauseUuid,
    body,
  }: {
    uuid: ParamsOf<'templates/:uuid/clauses/:clauseUuid/patch'>['uuid'];
    clauseUuid: ParamsOf<'templates/:uuid/clauses/:clauseUuid/patch'>['clauseUuid'];
    body: BodyOf<'templates/:uuid/clauses/:clauseUuid/patch'>;
  }) => {
    return client(api.drafting.templates.clauses.update, {
      method: 'PATCH',
      params: { uuid, clauseUuid },
      body,
    });
  };

  const moveTemplateClause = ({
    uuid,
    clauseUuid,
    body,
  }: {
    uuid: ParamsOf<'templates/:uuid/clauses/:clauseUuid/move/put'>['uuid'];
    clauseUuid: ParamsOf<'templates/:uuid/clauses/:clauseUuid/move/put'>['clauseUuid'];
    body: BodyOf<'templates/:uuid/clauses/:clauseUuid/move/put'>;
  }) => {
    return client(api.drafting.templates.clauses.move, {
      method: 'PUT',
      body,
      params: { uuid, clauseUuid },
    });
  };

  const deleteTemplateClause = ({
    uuid,
    clauseUuid,
  }: ParamsOf<'templates/:uuid/clauses/:clauseUuid/delete'>) => {
    return client(api.drafting.templates.clauses.delete, {
      method: 'DELETE',
      params: { uuid, clauseUuid },
    });
  };

  const createDraftClause = ({
    uuid,
    body,
  }: {
    uuid: ParamsOf<'drafts/:uuid/clauses/post'>['uuid'];
    body: BodyOf<'drafts/:uuid/clauses/post'>;
  }) => {
    return client(api.drafting.drafts.clauses.create, { method: 'POST', body, params: { uuid } });
  };

  const updateDraftClause = ({
    uuid,
    clauseUuid,
    body,
  }: {
    uuid: ParamsOf<'drafts/:uuid/clauses/:clauseUuid/patch'>['uuid'];
    clauseUuid: ParamsOf<'drafts/:uuid/clauses/:clauseUuid/patch'>['clauseUuid'];
    body: BodyOf<'drafts/:uuid/clauses/:clauseUuid/patch'>;
  }) => {
    return client(api.drafting.drafts.clauses.update, {
      method: 'PATCH',
      params: { uuid, clauseUuid },
      body,
    });
  };

  const moveDraftClause = ({
    uuid,
    clauseUuid,
    body,
  }: {
    uuid: ParamsOf<'drafts/:uuid/clauses/:clauseUuid/move/put'>['uuid'];
    clauseUuid: ParamsOf<'drafts/:uuid/clauses/:clauseUuid/move/put'>['clauseUuid'];
    body: BodyOf<'drafts/:uuid/clauses/:clauseUuid/move/put'>;
  }) => {
    return client(api.drafting.drafts.clauses.move, {
      method: 'PUT',
      body,
      params: { uuid, clauseUuid },
    });
  };

  const deleteDraftClause = ({
    uuid,
    clauseUuid,
  }: ParamsOf<'drafts/:uuid/clauses/:clauseUuid/delete'>) => {
    return client(api.drafting.drafts.clauses.delete, {
      method: 'DELETE',
      params: { uuid, clauseUuid },
    });
  };

  return {
    getDraftingTemplates,
    getDraftingTemplate,
    createDraftingTemplate,
    updateDraftingTemplate,
    deleteDraftingTemplate,
    getDrafts,
    getDraft,
    createDraft,
    updateDraft,
    deleteDraft,
    downloadDraftAsPdf,
    downloadDraftAsDocx,
    createTemplateClause,
    updateTemplateClause,
    moveTemplateClause,
    deleteTemplateClause,
    createDraftClause,
    updateDraftClause,
    moveDraftClause,
    deleteDraftClause,
  };
};

export type DraftingApi = ReturnType<typeof createDraftingApi>;
export type ApiTemplate = ResponseOf<'templates/:uuid/get'>;
export type ApiClause = ApiTemplate['clauses'][number] | ApiDraft['clauses'][number];
export type ApiDraft = ResponseOf<'drafts/:uuid/get'>;
export type ApiDraftClause = ApiDraft['clauses'][number];
export type ApiDraftStats = ResponseOf<'drafts/get'>['stats'];
export type ApiDraftList = ResponseOf<'drafts/get'>;
