import type { BaseSorting } from '../core/types';

export enum DocumentEntityType {
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  AGE = 'AGE',
  AGE_RANGE = 'AGE_RANGE',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BLOOD_TYPE = 'BLOOD_TYPE',
  CERTIFICATION = 'CERTIFICATION',
  CONDITION = 'CONDITION',
  COOKIE_ID = 'COOKIE_ID',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT_CARD_EXPIRATION = 'CREDIT_CARD_EXPIRATION',
  CVV = 'CVV',
  DATE = 'DATE',
  DATE_INTERVAL = 'DATE_INTERVAL',
  DIETARY_RESTRICTION = 'DIETARY_RESTRICTION',
  DISCIPLINE = 'DISCIPLINE',
  DOB = 'DOB',
  DOSE = 'DOSE',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  DRUG = 'DRUG',
  DURATION = 'DURATION',
  EFFECT = 'EFFECT',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  EVENT = 'EVENT',
  FILENAME = 'FILENAME',
  GENDER = 'GENDER',
  HEALTHCARE_NUMBER = 'HEALTHCARE_NUMBER',
  IBAN = 'IBAN',
  INJURY = 'INJURY',
  IP_ADDRESS = 'IP_ADDRESS',
  LANGUAGE = 'LANGUAGE',
  LICENSE_PLATE = 'LICENSE_PLATE',
  LOCATION = 'LOCATION',
  LOCATION_ADDRESS = 'LOCATION_ADDRESS',
  LOCATION_ADDRESS_STREET = 'LOCATION_ADDRESS_STREET',
  LOCATION_CITY = 'LOCATION_CITY',
  LOCATION_COORDINATE = 'LOCATION_COORDINATE',
  LOCATION_COUNTRY = 'LOCATION_COUNTRY',
  LOCATION_STATE = 'LOCATION_STATE',
  LOCATION_ZIP = 'LOCATION_ZIP',
  MAC_ADDRESS = 'MAC_ADDRESS',
  MARITAL_STATUS = 'MARITAL_STATUS',
  MEDICAL_CODE = 'MEDICAL_CODE',
  MEDICAL_DEVICE_SERIAL_NUMBER = 'MEDICAL_DEVICE_SERIAL_NUMBER',
  MEDICAL_PROCESS = 'MEDICAL_PROCESS',
  MEDICAL_RECORD_NUMBER = 'MEDICAL_RECORD_NUMBER',
  MONEY = 'MONEY',
  NAME = 'NAME',
  NAME_FAMILY = 'NAME_FAMILY',
  NAME_GIVEN = 'NAME_GIVEN',
  NAME_MEDICAL_PROFESSIONAL = 'NAME_MEDICAL_PROFESSIONAL',
  NUMERICAL_PII = 'NUMERICAL_PII',
  OCCUPATION = 'OCCUPATION',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_MEDICAL_FACILITY = 'ORGANIZATION_MEDICAL_FACILITY',
  ORIGIN = 'ORIGIN',
  PASSPORT_NUMBER = 'PASSPORT_NUMBER',
  PASSWORD = 'PASSWORD',
  PHONE_NUMBER = 'PHONE_NUMBER',
  PHYSICAL_ATTRIBUTE = 'PHYSICAL_ATTRIBUTE',
  PIN = 'PIN',
  POLITICAL_AFFILIATION = 'POLITICAL_AFFILIATION',
  PRODUCT = 'PRODUCT',
  PROFESSIONAL_TITLE = 'PROFESSIONAL_TITLE',
  RELIGION = 'RELIGION',
  RELIGION_EXT = 'RELIGION_EXT',
  ROUTING_NUMBER = 'ROUTING_NUMBER',
  SEXUALITY = 'SEXUALITY',
  SKILL = 'SKILL',
  SSN = 'SSN',
  STATISTICS = 'STATISTICS',
  TIME = 'TIME',
  URL = 'URL',
  USERNAME = 'USERNAME',
  VEHICLE_SERIAL = 'VEHICLE_SERIAL',
  VEHICLE_ID = 'VEHICLE_ID',
  VERIFICATION_ANSWER = 'VERIFICATION_ANSWER',
  ZODIAC_SIGN = 'ZODIAC_SIGN',
}

export enum DocumentType {
  FILE = 'file',
  FOLDER = 'folder',
}

export enum DocumentVisibility {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum DocumentOrigin {
  DOCUMENTS = 'documents',
  DISCOVERY = 'discovery',
  ANONYMIZATION = 'anonymization',
  REVIEW = 'review',
  MULTI_REVIEW = 'multi-review',
  AGENTS = 'agents',
  OFFICE_WORD = 'office-word',
  TRANSLATE = 'translate',
}

export enum DocumentFileType {
  PDF = 'pdf',
  WORD = 'word',
  TXT = 'txt',
  JPG = 'jpg',
  PNG = 'png',
  EMAIL = 'email',
}

export enum DocumentCategory {
  UNDEFINED_DOCUMENT = 'Undefined Document',
  ACCEPTABLE_USE_POLICY = 'Acceptable Use Policy',
  ACCESSIBILITY_STATEMENT = 'Accessibility Statement',
  ADVERTISING_AGREEMENT = 'Advertising Agreement',
  ANTI_SPAM_POLICY = 'Anti-Spam Policy',
  AGENCY_AGREEMENT = 'Agency Agreement',
  ARBITRATION_AGREEMENT = 'Arbitration Agreement',
  ARTWORK_RELEASE_AGREEMENT = 'Artwork Release Agreement',
  ASSET_PURCHASE_AGREEMENT = 'Asset Purchase Agreement',
  ASSIGNMENT_AGREEMENT = 'Assignment Agreement',
  B2B_TERMS_AND_CONDITIONS = 'B2B Terms & Conditions',
  B2C_TERMS_AND_CONDITIONS = 'B2C Terms & Conditions',
  BANK_GIFT = 'Bank Gift',
  BILL_OF_SALE = 'Bill of Sale',
  BILLING_TERMS = 'Billing Terms',
  BUILDING_RIGHTS_AGREEMENT = 'Building Rights Agreement',
  CCPA_STATEMENT = 'CCPA Statement',
  CLEAN_TEAM_AGREEMENT = 'Clean Team Agreement',
  COMMERCIAL_RENTAL_AGREEMENT = 'Commercial Rental Agreement',
  COMMISSION_AGREEMENT = 'Commission Agreement',
  COMMODITY_SALES_AGREEMENT = 'Commodity Sales Agreement',
  CONFIDENTIALITY_AGREEMENT = 'Confidentiality Agreement',
  CONSIGNMENT_AGREEMENT = 'Consignment Agreement',
  CONSTRUCTION_CONTRACT = 'Construction Contract',
  CONSULTING_AGREEMENT = 'Consulting Agreement',
  COOKIE_POLICY = 'Cookie Policy',
  COPYRIGHT_AGREEMENT = 'Copyright Agreement',
  COPYRIGHT_LICENSE = 'Copyright License',
  COPYRIGHT_NOTICE = 'Copyright Notice',
  DATA_PROCESSING_AGREEMENT = 'Data Processing Agreement',
  DATA_RETENTION_POLICY = 'Data Retention Policy',
  DEBT_SETTLEMENT_AGREEMENT = 'Debt Settlement Agreement',
  DEED_OF_ASSIGNMENT = 'Deed of Assignment',
  DEED_OF_TRUST = 'Deed of Trust',
  DEFERRED_COMPENSATION_AGREEMENT = 'Deferred Compensation Agreement',
  DIRECTOR_RESIGNATION_LETTER = 'Director Resignation Letter',
  DISCLAIMER = 'Disclaimer',
  DISTRIBUTION_AGREEMENT = 'Distribution Agreement',
  DIVORCE_SETTLEMENT = 'Divorce Settlement',
  EMPLOYEE_NON_COMPETE_AGREEMENT = 'Employee Non-Compete Agreement',
  EMPLOYEE_NON_DISCLOSURE_AGREEMENT = 'Employee Non-Disclosure Agreement',
  EMPLOYMENT_CONTRACT = 'Employment Contract',
  EMPLOYMENT_SEPARATION_AGREEMENT = 'Employment Separation Agreement',
  END_USER_LICENSE_AGREEMENT = 'End User License Agreement',
  ENVIRONMENTAL_POLICY_STATEMENT = 'Environmental Policy Statement',
  EQUIPMENT_LEASE_AGREEMENT = 'Equipment Lease Agreement',
  EXCLUSIVITY_AGREEMENT = 'Exclusivity Agreement',
  FACILITY_USE_AGREEMENT = 'Facility Use Agreement',
  FAIR_USE_POLICY = 'Fair Use Policy',
  FINANCIAL_DISCLOSURE_STATEMENT = 'Financial Disclosure Statement',
  FINDERS_FEE_AGREEMENT = "Finder's Fee Agreement",
  FRANCHISE_AGREEMENT = 'Franchise Agreement',
  FRAMEWORK_PURCHASE_AGREEMENT = 'Framework Purchase Agreement',
  GDPR_COMPLIANCE_STATEMENT = 'GDPR Compliance Statement',
  HEALTH_INFORMATION_PRIVACY_NOTICE = 'Health Information Privacy Notice',
  IMMEDIATE_TERMINATION_LETTER = 'Immediate Termination Letter',
  INDEMNITY_AGREEMENT = 'Indemnity Agreement',
  INDEPENDENT_CONTRACTOR_AGREEMENT = 'Independent Contractor Agreement',
  INSURANCE_POLICY = 'Insurance Policy',
  INVESTMENT_PROSPECTUS = 'Investment Prospectus',
  JOINT_VENTURE_AGREEMENT = 'Joint Venture Agreement',
  LABOUR_REGULATIONS = 'Labour Regulations',
  LANDLORDS_CONSENT_TO_LEASE_ASSIGNMENT = "Landlord's Consent to Lease Assignment",
  LETTER_OF_INTENT = 'Letter of Intent',
  LICENSING_AGREEMENT = 'Licensing Agreement',
  LIFE_INSURANCE = 'Life Insurance',
  LIMITED_PARTNERSHIP_AGREEMENT = 'Limited Partnership Agreement',
  LOAN_AGREEMENT = 'Loan Agreement',
  LONG_LEASE_AGREEMENT = 'Long Lease Agreement',
  MANUFACTURING_AGREEMENT = 'Manufacturing Agreement',
  MERGER_AGREEMENT = 'Merger Agreement',
  MORTGAGE_AGREEMENT = 'Mortgage Agreement',
  NON_COMPETE_AGREEMENT = 'Non-Compete Agreement',
  NON_DISCLOSURE_AGREEMENT = 'Non-Disclosure Agreement',
  NOTICE_OF_BREACH_OF_CONTRACT = 'Notice of Breach of Contract',
  OFFICE_SPACE_PROVISION_AGREEMENT = 'Office Space Provision Agreement',
  OFFSHORE_CONTRACT = 'Offshore Contract',
  OUTSOURCING_SERVICES_AGREEMENT = 'Outsourcing Services Agreement',
  PARTNERSHIP_AGREEMENT = 'Partnership Agreement',
  PATENT_STATEMENT = 'Patent Statement',
  PERSONAL_GUARANTEE = 'Personal Guarantee',
  POWER_OF_ATTORNEY = 'Power of Attorney',
  PRENUPTIAL_AGREEMENT = 'Prenuptial Agreement',
  PRIVACY_POLICY = 'Privacy Policy',
  PROPERTY_MANAGEMENT_AGREEMENT = 'Property Management Agreement',
  PURCHASE_ORDER = 'Purchase Order',
  REAL_ESTATE_PURCHASE_AGREEMENT = 'Real Estate Purchase Agreement',
  RELEASE_AGREEMENT = 'Release Agreement',
  RESIDENTIAL_RENTAL_AGREEMENT = 'Residential Rental Agreement',
  REVOCABLE_LIVING_TRUST = 'Revocable Living Trust',
  SAFE_HARBOR_STATEMENT = 'Safe Harbor Statement',
  SALE_CONTRACT = 'Sale Contract',
  SECURITY_AGREEMENT = 'Security Agreement',
  SERVICE_AGREEMENT = 'Service Agreement',
  SETTLEMENT_AGREEMENT = 'Settlement Agreement',
  SEVERANCE_AGREEMENT = 'Severance Agreement',
  SHARE_PURCHASE_AGREEMENT = 'Share Purchase Agreement',
  SHAREHOLDER_AGREEMENT = 'Shareholder Agreement',
  SOFTWARE_DEVELOPMENT_AGREEMENT = 'Software Development Agreement',
  SOFTWARE_LICENSE_AGREEMENT = 'Software License Agreement',
  SPONSORSHIP_AGREEMENT = 'Sponsorship Agreement',
  STOCK_OPTION_AGREEMENT = 'Stock Option Agreement',
  SUBCONTRACTOR_AGREEMENT = 'Subcontractor Agreement',
  SUBSCRIPTION_AGREEMENT = 'Subscription Agreement',
  SUPPLIER_TERMS = 'Supplier Terms',
  SUPPLY_AGREEMENT = 'Supply Agreement',
  TERMINATION_LETTER_WITH_NOTICE = 'Termination Letter With Notice',
  TERMS_OF_SERVICE_AGREEMENT = 'Terms of Service Agreement',
  TRADEMARK_NOTICE = 'Trademark Notice',
  TRANSPORT_CONTRACT = 'Transport Contract',
  TYPE_FRAMEWORK_AGREEMENT = 'Type Framework Agreement',
  TYPE_MANAGEMENT_AGREEMENT = 'Type Management Agreement',
  TYPE_TERM_SHEET = 'Type Term Sheet',
  USER_AGREEMENT = 'User Agreement',
  WARRANTY_AGREEMENT = 'Warranty Agreement',
  WILL_AND_TESTAMENT = 'Will and Testament',
  VEHICLE_LEASE_AGREEMENT = 'Vehicle Lease Agreement',
  VEHICLE_PURCHASE_AGREEMENT = 'Vehicle Purchase Agreement',
  VEHICLE_RENTAL_AGREEMENT = 'Vehicle Rental Agreement',
}

export type DocumentCategoryKey = keyof typeof DocumentCategory;

export enum DocumentStatus {
  PENDING = 'pending',
  ANONYMIZING = 'anonymizing',
  VECTORIZING = 'vectorizing',
  DETERMINING_DOCUMENT_PROPERTIES = 'determining_document_properties',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export const DOCUMENT_SORT_CONFIG = {
  createdAt: 'createdAt',
  owner: 'owner.firstName',
  name: 'name',
} as const;

export type DocumentSortField = keyof typeof DOCUMENT_SORT_CONFIG;
export type MappedDocumentSortField = (typeof DOCUMENT_SORT_CONFIG)[DocumentSortField];

export const DEFAULT_DOCUMENT_SORT: BaseSorting<DocumentSortField> = {
  sortBy: 'createdAt',
  sortOrder: 'DESC',
} as const;

export type DefaultDocumentSort = typeof DEFAULT_DOCUMENT_SORT;
