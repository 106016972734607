import type { BaseSorting } from '../core/types';

export enum ClauseTypeEnum {
  TEMPLATE = 'template',
  DRAFT = 'draft',
}

export const ClauseStatusValues = ['progress', 'done'] as const;

export type ClauseStatus = (typeof ClauseStatusValues)[number];

export const TEMPLATE_SORT_CONFIG = {
  category: 'category',
  createdAt: 'createdAt',
  title: 'title',
  owner: 'owner.firstName',
} as const;

export type TemplateSortField = keyof typeof TEMPLATE_SORT_CONFIG;
export type MappedTemplateSortField = (typeof TEMPLATE_SORT_CONFIG)[TemplateSortField];
export const DRAFTING_SORT_CONFIG = {
  createdAt: 'createdAt',
  category: 'category',
  title: 'title',
} as const;

export type DraftingSortField = keyof typeof DRAFTING_SORT_CONFIG;
export type MappedDraftingSortField = (typeof DRAFTING_SORT_CONFIG)[DraftingSortField];

export const DEFAULT_DRAFTING_SORT: BaseSorting<DraftingSortField> = {
  sortBy: 'createdAt',
  sortOrder: 'DESC',
} as const;

export type DefaultDraftingSort = typeof DEFAULT_DRAFTING_SORT;

export const DEFAULT_TEMPLATE_SORT: BaseSorting<DraftingSortField> = {
  sortBy: 'title',
  sortOrder: 'DESC',
} as const;

export type DefaultTemplateSort = typeof DEFAULT_TEMPLATE_SORT;
