import { createFileRoute } from '@tanstack/react-router';

import { TranslateFilesBox } from 'components/translate/TranslateFilesBox';
import { routeRedirectWithFeatureFlag } from 'core/analytics';

export const Route = createFileRoute('/_auth/_layout/translate/_layout/files/')({
  beforeLoad: async ({ context }) =>
    routeRedirectWithFeatureFlag(context.queryClient, 'translate-file-agent'),
  component: TranslateFilesRoute,
});

function TranslateFilesRoute() {
  return <TranslateFilesBox />;
}
