import type { ComponentProps, HTMLAttributes, LabelHTMLAttributes } from 'react';

import { cn } from 'utils';

import { Text } from '../text';

type FormItemProps = HTMLAttributes<HTMLDivElement>;

const FormItem = ({ className, ...props }: FormItemProps) => (
  <div className={cn('flex flex-col', className)} {...props} />
);

type FormItemLabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  label: string;
  required?: boolean;
} & ComponentProps<typeof Text>;

const FormItemLabel = ({ label, className, required, ...props }: FormItemLabelProps) => (
  <Text
    as='label'
    className={cn('mb-2', className)}
    {...props}
  >{`${label}${required ? ' *' : ''}`}</Text>
);

const FormItemError = ({ message }: { message: string }) => (
  <Text className='text-content-body-error'>{message}</Text>
);

export { FormItem, FormItemLabel, FormItemError };
