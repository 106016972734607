import { useEffect, useRef, useState } from 'react';
import { useForm } from '@tanstack/react-form';

import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Input } from '@legalfly/ui/input';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

interface Props {
  value: string;
  onsubmit: (value: string) => void;
  canEdit?: boolean;
}

export const EditableValueForm = ({ value: defaultValue, onsubmit, canEdit = true }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const defaultValues = {
    name: defaultValue,
  };

  type FormData = typeof defaultValues;

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const form = useForm<FormData>({
    defaultValues,
    onSubmit: async ({ value }) => {
      if (value.name !== defaultValue) {
        onsubmit(value.name);
      }

      setIsEditing(false);
    },
  });

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      className='w-full flex-1'
    >
      <form.Field name='name'>
        {(field) => (
          <>
            {canEdit && isEditing && (
              <Input
                ref={inputRef}
                containerClassName='w-full'
                id={field.name}
                name={field.name}
                value={field.state.value}
                onChange={(e) => field.handleChange(e.target.value)}
                onFocus={handleInputFocus}
                onBlur={() => {
                  field.handleBlur();
                  form.handleSubmit();
                }}
                underlineOnError={field.state.meta.errors.length > 0}
                className={cn(
                  'm-0 h-auto w-full truncate border-none bg-transparent p-0 focus-visible:outline-offset-8',
                )}
                placeholder={defaultValue}
              />
            )}

            {canEdit && !isEditing && (
              <Button
                variant='unstyled'
                className='flex gap-3 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-stroke-weak'
                onClick={handleClick}
                renderRight={<Icon name='edit' className='text-icon-weak' size='sm' />}
              >
                {field.state.value}
              </Button>
            )}

            {!canEdit && <Text>{field.state.value}</Text>}
          </>
        )}
      </form.Field>
    </form>
  );
};
