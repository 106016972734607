import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiPlaybook } from '@legalfly/api/playbooks';
import { Combobox, ComboboxContent, ComboboxTrigger } from '@legalfly/ui/combobox';
import { Icon } from '@legalfly/ui/icon';

interface Props {
  playbooks: ApiPlaybook[];
  selectedPlaybooks?: ApiPlaybook[];
  triggerProps?: ComponentProps<typeof ComboboxTrigger>;
  contentProps?: Omit<
    ComponentProps<typeof ComboboxContent>,
    'items' | 'filterKey' | 'valueKey' | 'displayKey'
  >;
  onChange: (playbook: ApiPlaybook) => void;
}

export const SelectPlaybook = ({
  playbooks,
  selectedPlaybooks,
  triggerProps,
  contentProps,
  onChange,
}: Props) => {
  const { t } = useTranslation('components');

  return (
    <Combobox
      value={undefined}
      onChange={(playbookUuid: ApiPlaybook['uuid']) => {
        const playbook = playbooks.find((p) => p.uuid === playbookUuid)!;
        onChange(playbook);
      }}
    >
      <ComboboxTrigger {...triggerProps}>
        <div className='flex items-center gap-2'>
          <Icon name='book-open' />
          {t('playbooks.addPlaybook')}
        </div>
      </ComboboxTrigger>
      <ComboboxContent
        items={playbooks.filter(
          (playbook) => !selectedPlaybooks?.some((p) => p.uuid === playbook.uuid),
        )}
        displayKey='name'
        valueKey='uuid'
        comboboxInputProps={{
          placeholder: t('playbooks.addPlaybook'),
        }}
        comboboxEmptyProps={{
          children: t('playbooks.noPlaybooks'),
        }}
        {...contentProps}
      />
    </Combobox>
  );
};
