import { useCallback } from 'react';
import type { NavigateOptions } from '@tanstack/react-router';
import type { SortingState } from '@tanstack/react-table';
import { fromTanstackSorting, toTanstackSorting } from 'common/utils/sorting';

import type { BaseSorting } from '@legalfly/api/core';

interface UseSortableTableOptions<
  TSortField extends string,
  TSearchSchema = Record<string, unknown> & {
    sortBy?: TSortField;
    sortOrder?: 'ASC' | 'DESC';
  },
> {
  sorting: BaseSorting<TSortField>;
  defaultSortField: TSortField;
  navigate: (
    options: NavigateOptions & { search?: (prev: TSearchSchema) => TSearchSchema },
  ) => void;
}

export function useSortableTable<
  TSortField extends string,
  TSearchSchema = Record<string, unknown> & {
    sortBy?: TSortField;
    sortOrder?: 'ASC' | 'DESC';
  },
>({ sorting, defaultSortField, navigate }: UseSortableTableOptions<TSortField, TSearchSchema>) {
  const handleSort = useCallback(
    (newSorting: SortingState | ((old: SortingState) => SortingState)) => {
      const nextSorting = fromTanstackSorting<TSortField>(
        typeof newSorting === 'function' ? newSorting(toTanstackSorting(sorting)) : newSorting,
        defaultSortField,
      );

      navigate({
        search: (prev: TSearchSchema) => ({
          ...prev,
          sortBy: nextSorting.sortBy as TSortField,
          sortOrder: nextSorting.sortOrder,
        }),
        replace: true,
      });
    },
    [sorting, defaultSortField, navigate],
  );

  return {
    currentSorting: toTanstackSorting(sorting),
    onSort: handleSort,
  };
}
