import { createEnv } from '@legalfly/utils/env';

import { schema } from './schema';

export const env = createEnv(schema, {
  auth0: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    authId: import.meta.env.VITE_AUTH_ID,
  },
  posthog: {
    token: import.meta.env.VITE_POSTHOG_TOKEN,
  },
  environment: import.meta.env.VITE_ENVIRONMENT,
  isLocal: import.meta.env.VITE_ENVIRONMENT === 'local',
  userPilot: import.meta.env.VITE_USER_PILOT,
  isProduction: import.meta.env.VITE_ENVIRONMENT === 'production',
});
