import deepmerge from '@fastify/deepmerge';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { ConfigApi } from '@legalfly/api/config';
import type { SettingsApi } from '@legalfly/api/settings';

import { configQueryOptions } from '../config/configQueryOptions';
import { settingsQueryOptions } from './settingsQueryOptions';

const merge = deepmerge({ all: true });

export const createSettingsModule = ({
  settingsApi,
  configApi,
}: {
  settingsApi: SettingsApi;
  configApi: ConfigApi;
}) => {
  const queryOptions = settingsQueryOptions({ settingsApi });
  const queryOptionsConfig = configQueryOptions({ configApi });

  const useUserSettings = () => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.userSettings());

    return {
      userSettings: data,
      isLoading,
      error,
    };
  };

  const useUpdateUserSettings = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['userSettings', 'update'],
      mutationFn: settingsApi.updateUserSettings,
      onSuccess: (newUserSettings) => {
        queryClient.setQueryData(queryOptions.userSettings().queryKey, (userSettings) => {
          return merge(userSettings, newUserSettings);
        });
        if (newUserSettings.isOnboardingCompleted) {
          queryClient.invalidateQueries({ queryKey: queryOptionsConfig.config().queryKey });
        }
      },
    });

    return {
      updateUserSettings: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useUserSettings,
    useUpdateUserSettings,
    settingsQueryOptions: queryOptions,
  };
};
