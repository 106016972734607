import deepmerge from '@fastify/deepmerge';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { ApiReview, ApiReviews, ReviewsApi } from '@legalfly/api/reviews';

import { initReviewHelpers } from './helpers';
import { reviewsQueryOptions } from './reviewsQueryOptions';

const merge = deepmerge({ all: true });

export const createReviewsModule = ({ reviewsApi }: { reviewsApi: ReviewsApi }) => {
  const queryOptions = reviewsQueryOptions({ reviewsApi });
  const useReviewHelpers = initReviewHelpers(queryOptions);

  const useReviews = (query: Parameters<ReviewsApi['getReviews']>[0] = {}) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.reviews(query));

    return {
      reviews: data?.reviews,
      stats: data?.stats ?? undefined,
      isLoading,
      error,
    };
  };

  const useReview = ({ uuid }: { uuid: ApiReview['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.review({ uuid }));

    return {
      review: data,
      isLoading,
      error,
    };
  };

  const useCreateReview = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['review', 'create'],
      mutationFn: reviewsApi.createReview,
      onSuccess: (review) => {
        queryClient.invalidateQueries(queryOptions.reviews({ documentUuid: review.document.uuid }));
        queryClient.setQueryData(queryOptions.review({ uuid: review.uuid }).queryKey, review);
      },
    });

    return {
      createReview: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteReview = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['review', 'delete'],
      mutationFn: reviewsApi.deleteReview,
      onSuccess: (_, { uuid }) => {
        queryClient.setQueriesData(
          { queryKey: queryOptions.reviews().queryKey.slice(0, 1) },
          (data: ApiReviews | undefined) => {
            if (!data) return;
            return {
              ...data,
              reviews: data.reviews?.filter((review) => review.uuid !== uuid),
            };
          },
        );

        queryClient.removeQueries(queryOptions.review({ uuid }));
      },
    });

    return {
      deleteReview: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateReview = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['review', 'update'],
      mutationFn: reviewsApi.updateReview,
      onSuccess: (review) => {
        queryClient.setQueryData(queryOptions.review({ uuid: review.uuid }).queryKey, (old) => {
          return merge(review, old);
        });

        queryClient.setQueriesData(
          { queryKey: queryOptions.reviews().queryKey.slice(0, 1) },
          (data: ApiReviews | undefined) => {
            if (!data) return;
            return {
              ...data,
              reviews: data.reviews?.map((r) => (r.uuid === review.uuid ? merge(r, review) : r)),
            };
          },
        );
      },
    });

    return {
      updateReview: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useReviews,
    useReview,
    useCreateReview,
    useDeleteReview,
    useUpdateReview,
    useReviewHelpers,
    reviewsQueryOptions: queryOptions,
  };
};
