import type { Jurisdiction } from '@legalfly/api/core';
import type { TranslateLanguage } from 'components/translate/TranslateBoxLanguageSelector';

const PREFIX = 'lf_';

interface LocalStorageKeys {
  jurisdiction: Jurisdiction;
  sidebarExpanded: boolean;
  translateTargetLanguage: TranslateLanguage;
}

export class LocalStorageService {
  static setItem<T extends keyof LocalStorageKeys>(key: T, value: LocalStorageKeys[T]): void {
    localStorage.setItem(`${PREFIX}${key}`, JSON.stringify(value));
  }

  static getItem<T extends keyof LocalStorageKeys>(key: T): LocalStorageKeys[T] | undefined {
    const item = localStorage.getItem(`${PREFIX}${key}`);
    return item ? JSON.parse(item) : undefined;
  }

  static removeItem<T extends keyof LocalStorageKeys>(key: T): void {
    localStorage.removeItem(`${PREFIX}${key}`);
  }

  static clear(): void {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(PREFIX)) {
        localStorage.removeItem(key);
      }
    });
  }
}
