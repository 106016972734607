import { useMemo, useState } from 'react';

type Primitive = string | number | boolean | null | undefined;

type ExcludeParent<T> = Omit<T, 'parent'>;

type DotNotationHelper<T, Depth extends number[] = []> = Depth['length'] extends 4
  ? never
  : T extends Primitive
    ? ''
    : T extends (infer U)[]
      ? DotNotationHelper<U, [...Depth, 0]>
      : {
          [K in keyof ExcludeParent<T>]: K extends string
            ? T[K] extends Primitive
              ? K
              : `${K}.${DotNotationHelper<T[K], [...Depth, 0]>}`
            : never;
        }[keyof ExcludeParent<T>];

type DotNotation<T> = DotNotationHelper<T>;

// Check if the key exists in the object
// Updated to handle array paths
function hasNestedProperty(obj: unknown, keyPath: string | string[]): boolean {
  const keys = Array.isArray(keyPath) ? keyPath : keyPath.split('.');

  return keys.every((key) => {
    if (obj && typeof obj === 'object' && key in obj) {
      obj = (obj as Record<string, unknown>)[key];
      return true;
    }
    return false;
  });
}

export const useKeywordFilter = <T extends object>(
  items: T[],
  keyPath: DotNotation<T> | string[],
) => {
  const [keyword, setKeyword] = useState('');

  const filteredItems = useMemo(() => {
    if (!keyword || !items.length || !hasNestedProperty(items[0], keyPath)) return items;

    const keys = Array.isArray(keyPath) ? keyPath : (keyPath as string).split('.');
    const lowerKeyword = keyword.toLowerCase();

    return items.filter((item) => {
      let value: any = item;

      for (const key of keys) {
        // Loop through the keys and check if the key exists in the object
        // if the key exists, assign the value to the value variable
        // the value is what we're going to check against the keyword
        if (value && typeof value === 'object' && key in value) {
          value = value[key];
        } else {
          return false;
        }
      }

      // Handle array values by checking if any element includes the keyword
      if (Array.isArray(value)) {
        console.log('is array');
        return value.some(
          (v) =>
            (typeof v === 'string' || typeof v === 'number') &&
            String(v).toLowerCase().includes(lowerKeyword),
        );
      }

      // Original logic for non-array values
      return (
        (typeof value === 'string' || typeof value === 'number') &&
        String(value).toLowerCase().includes(lowerKeyword)
      );
    });
  }, [items, keyword, keyPath]);

  return { keyword, filteredItems, setKeyword };
};
