import type { Validator } from './validators';
import * as zot from './validators';

export function createEnv<T>(_: Validator<T>, data: unknown): T {
  return data as T;
}

export function validateEnv<T>(validator: Validator<T>, data: T): T {
  const result = validator(data, []);

  if (!result.success || result.data === null) {
    console.error('❌ Invalid environment variables:');
    result.errors?.forEach((error) => {
      console.error(` ⚠️  ${error.path.join('.')}: ${error.message}`);
    });
    process.exit(1);
  }

  console.log('✅ Environment variables validated successfully');
  return result.data;
}

export { zot };
