import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from '@tanstack/react-router';
import { useSidebar } from 'common/hooks/useSidebar';

import { IconButton } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@legalfly/ui/tooltip';
import { cn } from '@legalfly/ui/utils';
import { NavigationMenu } from 'components/common/navigationMenu';
import { useFeatureFlag } from 'core/analytics';

import { CustomAgentsNavigationItem } from './navigationItems/CustomAgentsNavigationItem';
import { ManagementNavigationItem } from './navigationItems/ManagementNavigationItem';

const createNavigationToolTip = (isExpanded: boolean) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(
    () =>
      function NavigationToolTip({ children, text }: { children: React.ReactNode; text: string }) {
        if (isExpanded) {
          return children;
        }

        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>{children}</TooltipTrigger>
              <TooltipPortal>
                <TooltipContent side='right' sideOffset={8}>
                  <span className='text-body-small'>{text}</span>
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </TooltipProvider>
        );
      },
    [isExpanded],
  );
};

export const LayoutNavigation = () => {
  const { t } = useTranslation();
  const { pathname: currentPath } = useLocation();
  const translateAgentEnabled = useFeatureFlag('translate-text-agent');
  const { isExpanded, handleExpandToggle } = useSidebar();

  const NavigationToolTip = createNavigationToolTip(isExpanded);

  const iconClasses = 'size-6 group-[.open]:size-5 group-[.open]:min-h-5 group-[.open]:min-w-5';
  const textClasses = 'hidden group-[.open]:inline truncate';

  return (
    <aside
      className={cn(
        isExpanded ? 'open w-[250px] min-w-[250px]' : 'w-20 min-w-20',
        'group relative',
        'flex flex-col bg-surface-nav px-4 pb-3 pt-6 transition-all duration-300',
      )}
      id='sidebar'
    >
      <NavigationMenu className='flex-1'>
        <NavigationToolTip text={t('nav.dashboard')}>
          <NavigationMenu.Item
            asChild
            isActive={currentPath === '/'}
            className='mb-2 group-[.open]:mb-2'
          >
            <Link to='/'>
              <Icon name='home-line' className={iconClasses} />
              <Text className={textClasses}>{t('nav.dashboard')}</Text>
            </Link>
          </NavigationMenu.Item>
        </NavigationToolTip>
        <div className='mb-2'>
          <Text
            variant='body'
            className='hidden px-6 pb-3 pt-4 text-content-body-weak group-[.open]:block'
          >
            {t('label.agents')}
          </Text>
          <NavigationMenu.Content>
            <NavigationToolTip text={t('nav.review')}>
              <NavigationMenu.Item asChild isActive={currentPath.startsWith('/review')}>
                <Link to='/review'>
                  <Icon name='check-done' className={iconClasses} />
                  <Text className={textClasses}>{t('nav.review')}</Text>
                </Link>
              </NavigationMenu.Item>
            </NavigationToolTip>
            <NavigationToolTip text={t('nav.multi-review')}>
              <NavigationMenu.Item asChild isActive={currentPath.startsWith('/multi-review')}>
                <Link to='/multi-review'>
                  <Icon name='table' className={iconClasses} />
                  <Text className={textClasses}>{t('nav.multi-review')}</Text>
                </Link>
              </NavigationMenu.Item>
            </NavigationToolTip>
            <NavigationToolTip text={t('nav.drafting')}>
              <NavigationMenu.Item asChild isActive={currentPath.startsWith('/drafting')}>
                <Link to='/drafting/drafts'>
                  <Icon name='edit' className={iconClasses} />
                  <Text className={textClasses}>{t('nav.drafting')}</Text>
                </Link>
              </NavigationMenu.Item>
            </NavigationToolTip>
            <NavigationToolTip text={t('nav.discovery')}>
              <NavigationMenu.Item asChild isActive={currentPath.startsWith('/discovery')}>
                <Link to='/discovery'>
                  <Icon name='file-search-3' className={iconClasses} />
                  <Text className={textClasses}>{t('nav.discovery')}</Text>
                </Link>
              </NavigationMenu.Item>
            </NavigationToolTip>
            {translateAgentEnabled && (
              <NavigationToolTip text={t('nav.translation')}>
                <NavigationMenu.Item asChild isActive={currentPath.startsWith('/translate')}>
                  <Link to='/translate/text'>
                    <Icon name='translate-1' className={iconClasses} />
                    <Text className={textClasses}>{t('nav.translation')}</Text>
                  </Link>
                </NavigationMenu.Item>
              </NavigationToolTip>
            )}
            <NavigationToolTip text={t('nav.anonymisation')}>
              <NavigationMenu.Item asChild isActive={currentPath.startsWith('/anonymisation')}>
                <Link to='/anonymisation'>
                  <Icon name='shield-tick' className={iconClasses} />
                  <Text className={textClasses}>{t('nav.anonymisation')}</Text>
                </Link>
              </NavigationMenu.Item>
            </NavigationToolTip>
          </NavigationMenu.Content>
        </div>

        <div>
          <Text
            variant='body'
            className='hidden truncate px-6 pb-3 pt-4 text-content-body-weak group-[.open]:block'
          >
            {t('nav.agents')}
          </Text>
          <Divider variant='weak' className='my-3 block group-[.open]:hidden' />

          <CustomAgentsNavigationItem isExpanded={isExpanded} />
        </div>

        <div>
          <Text
            variant='body'
            className='hidden px-6 pb-3 pt-4 text-content-body-weak group-[.open]:block'
          >
            {t('label.knowledge')}
          </Text>
          <Divider variant='weak' className='my-3 block group-[.open]:hidden' />
          <ManagementNavigationItem isExpanded={isExpanded} />
        </div>
      </NavigationMenu>

      <IconButton
        name={isExpanded ? 'chevron-left' : 'chevron-right'}
        onClick={handleExpandToggle}
        className={cn('text-icon-strongest', isExpanded ? 'self-end' : 'self-center')}
        variant='tertiary'
        size='sm'
      />
    </aside>
  );
};
