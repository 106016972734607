import { Trans, useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';
import { useTableHeight } from 'common/hooks/useTableHeight';

import type { ApiUser } from '@legalfly/api/users';
import { UserRole } from '@legalfly/api/users';
import { UserAvatar } from '@legalfly/components/users/UserAvatar';
import { UserStatistics } from '@legalfly/components/users/UserStatistics';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Divider } from '@legalfly/ui/divider';
import { List, ListItem } from '@legalfly/ui/list';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@legalfly/ui/select';
import { Spinner } from '@legalfly/ui/spinner';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { CreateUserDialog, UsersTableActionPopover } from 'components/users';
import {
  isActiveUser,
  isAdminUser,
  useCurrentUser,
  usersQueryOptions,
  useUpdateUser,
  useUsers,
} from 'core/modules/users';
import { userToasts } from 'core/modules/users/toasts';

export const Route = createFileRoute('/_auth/_layout/settings/users')({
  component: UsersRoute,
  loader({ context }) {
    return context.queryClient.ensureQueryData(usersQueryOptions.users());
  },
  pendingComponent: () => (
    <div className='flex flex-1 items-center justify-center'>
      <Spinner />
    </div>
  ),
});

function UsersRoute() {
  const { t } = useTranslation();
  const { users, availableSeats, seats, userStatistics } = useUsers();
  const { currentUser } = useCurrentUser();
  const { updateUser } = useUpdateUser();
  const { tableHeight, tableRef } = useTableHeight();

  const isCurrentUserAdmin = isAdminUser(currentUser);
  const isCurrentUserActive = isActiveUser(currentUser);
  const canPerformActions = isCurrentUserAdmin && isCurrentUserActive;
  const hasInsufficientSeats = availableSeats >= seats;

  const userRoleTranslationKeys = {
    [UserRole.USER]: t('config.userRole.user'),
    [UserRole.ADMIN]: t('config.userRole.admin'),
    [UserRole.SUPERADMIN]: t('config.userRole.superadmin'),
  };

  const onChangeUserRole = (user: ApiUser, role: UserRole) => {
    trackEvent({
      action: 'click',
      category: 'users',
      label: 'changeRole',
    });
    withToasts(updateUser({ uuid: user.uuid, body: { role } }))(userToasts.updateUser());
  };

  return (
    <Content>
      <div className='mx-auto w-full max-w-screen-lg'>
        <div className='flex'>
          <ContentHeader className='flex-1'>
            <ContentHeader.Title>{t('settings.users.heading')}</ContentHeader.Title>
            <ContentHeader.SubTitle>{t('settings.users.subtitle')}</ContentHeader.SubTitle>
          </ContentHeader>

          <div className='mb-6 flex flex-1 flex-col justify-end'>
            <div className='flex items-center justify-end gap-2'>
              <Text variant='bodyLight' className='text-content-body-strong'>
                {t('label.seat', { count: seats })} {availableSeats} / {seats}
              </Text>
              {canPerformActions && (
                <CreateUserDialog hasInsufficientSeats={hasInsufficientSeats} />
              )}
            </div>
          </div>
        </div>

        {hasInsufficientSeats && (
          <>
            <Divider className='mb-6' />
            <Text variant='bodyLight' className='mb-6 text-content-body-strong'>
              <Trans
                i18nKey='settings.users.insufficientSeatsActionDescription2'
                components={{
                  ContactLink: (
                    <a
                      className='text-content-body-tag'
                      href={`mailto:${t('settings.contact.email')}`}
                    >
                      {t('settings.contact.label')}
                    </a>
                  ),
                }}
              />
            </Text>
          </>
        )}
        <div className='border border-stroke-weaker'>
          <Text variant='body' className='mb-4 px-6 pt-4 text-body'>
            {t('settings.users.title', { count: users.length })}
          </Text>
          <div ref={tableRef} className='overflow-y-auto' style={{ height: tableHeight }}>
            <List className='px-6 pb-6'>
              {users.map((user) => {
                const isAdmin = isAdminUser(user);
                const isActive = isActiveUser(user);

                return (
                  <ListItem
                    key={user.uuid}
                    className={cn(
                      'grid items-center gap-3 active:bg-fill-hover-weak',
                      canPerformActions
                        ? 'grid-cols-[auto_1fr_minmax(80px,auto)_minmax(80px,auto)_36px]'
                        : 'grid-cols-[auto_1fr_minmax(80px,auto)_minmax(80px,auto)]',
                      !isActive && 'opacity-50',
                    )}
                  >
                    <UserAvatar {...user} />
                    <div className='flex flex-col text-left'>
                      <Text>
                        {user.firstName} {user.lastName}
                      </Text>
                      <Text variant='bodyLight' className='text-content-body-strong'>
                        {user.email}
                      </Text>
                    </div>
                    {isCurrentUserAdmin && (
                      <UserStatistics statistics={userStatistics?.[user.uuid]} className='me-8' />
                    )}
                    {canPerformActions && user.uuid !== currentUser.uuid ? (
                      <Select
                        value={user.role}
                        onValueChange={(value) => {
                          onChangeUserRole(user, value);
                        }}
                      >
                        <SelectTrigger className='min-w-28'>
                          <SelectValue>{userRoleTranslationKeys[user.role]}</SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={UserRole.USER}>
                            {userRoleTranslationKeys[UserRole.USER]}
                          </SelectItem>
                          <SelectItem value={UserRole.ADMIN}>
                            {userRoleTranslationKeys[UserRole.ADMIN]}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    ) : (
                      <Text
                        variant='bodyLight'
                        className='min-w-28 truncate px-3 text-right'
                        title={user.role}
                      >
                        {userRoleTranslationKeys[user.role]}
                      </Text>
                    )}
                    {canPerformActions && !isAdmin && <UsersTableActionPopover user={user} />}
                  </ListItem>
                );
              })}
            </List>
          </div>
        </div>
      </div>
    </Content>
  );
}
