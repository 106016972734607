export const TABLE_COLUMN_SIZES = {
  NAME: 200,
  TITLE: 200,
  CATEGORY: 120,
  JURISDICTION: 150,
  STATUS: 120,
  OWNER: 80,
  CREATED_AT: 140,
  ACTIONS: 30,
  SELECT: 50,
} as const;

export type TableColumnSizeKey = keyof typeof TABLE_COLUMN_SIZES;
