import { queryOptions } from '@tanstack/react-query';

import type { ApiMultiReview, MultiReviewsApi } from '@legalfly/api/multi-reviews';

export const multiReviewsQueryOptions = ({ multiReviewsApi }: { multiReviewsApi: MultiReviewsApi }) => ({
  multiReviews: (query?: Parameters<MultiReviewsApi['getMultiReviews']>[0]) =>
    queryOptions({
      queryKey: ['multi-reviews', query?.sortBy, query?.sortOrder],
      queryFn: () => multiReviewsApi.getMultiReviews(query ?? {}),
    }),
  multiReview: ({ uuid }: { uuid: ApiMultiReview['uuid'] }) =>
    queryOptions({
      queryKey: ['multi-review', uuid],
      queryFn: () => multiReviewsApi.getMultiReview({ uuid }),
    }),
});
