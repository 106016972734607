import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createCommentsApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getComments = (params: ParamsOf<'comments/:entityType/:entityUuid/get'>) => {
    return client(api.comments.get, { method: 'GET', params });
  };

  const addComment = ({
    params,
    body,
  }: {
    params: ParamsOf<'comments/:entityType/:entityUuid/post'>;
    body: BodyOf<'comments/:entityType/:entityUuid/post'>;
  }) => {
    return client(api.comments.add, { method: 'POST', params, body });
  };

  const deleteComment = ({
    params,
    body,
  }: {
    params: ParamsOf<'comments/:commentUuid/delete'>;
    body: BodyOf<'comments/:commentUuid/delete'>;
  }) => {
    return client(api.comments.delete, { method: 'DELETE', params, body });
  };

  const markAllAsSeen = ({
    params,
  }: {
    params: ParamsOf<'comments/:entityType/:entityUuid/mark-all-as-seen/post'>;
  }) => {
    return client(api.comments.markAllAsSeen, { method: 'POST', params });
  };

  return {
    getComments,
    addComment,
    deleteComment,
    markAllAsSeen,
  };
};

export type CommentsApi = ReturnType<typeof createCommentsApi>;
export type ApiComment = ResponseOf<'comments/:entityType/:entityUuid/get'>[number];
