import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const conversationToasts = createToasts({
  deleteConversation: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteConversation.description'),
    }),
  }),
  createConversation: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createConversation.description'),
    }),
  }),
  stopConversationMessage: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.stopConversationMessage.description'),
    }),
  }),
  updateConversation: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateConversation.description'),
    }),
  }),
  clearConversation: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.clearConversation.description'),
    }),
  }),
  sendRedraftMessage: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.sendRedraftMessage.description'),
    }),
  }),
});
