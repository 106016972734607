import React from 'react';

export function fixedForwardRef<T, P = object>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode,
  displayName: string,
): (props: P & React.RefAttributes<T>) => React.ReactNode {
  // @ts-expect-error: tis ok
  const Component = React.forwardRef(render) as any;
  displayName && (Component.displayName = displayName);

  return Component;
}
