import type { SortingState } from '@tanstack/react-table';

import type { BaseSorting } from '@legalfly/api/core';

export function toTanstackSorting<TSortField extends string>(
  sorting: BaseSorting<TSortField>,
): SortingState {
  return [
    {
      id: sorting.sortBy,
      desc: sorting.sortOrder === 'DESC',
    },
  ];
}

export function fromTanstackSorting<TSortField extends string>(
  sortingState: SortingState,
  defaultSortField: TSortField,
): BaseSorting<TSortField> {
  const [firstSort] = sortingState;
  return {
    sortBy: (firstSort?.id ?? defaultSortField) as TSortField,
    sortOrder: firstSort?.desc ? 'DESC' : 'ASC',
  };
}
