import { FetcherError } from './FetcherError';
import type { FetcherHooks } from './types';

export class Fetcher {
  constructor(
    public apiUrl: string,
    public hooks: FetcherHooks,
  ) {}

  public async request(url: string, options: RequestInit = {}) {
    const fullUrl = this.apiUrl + url;

    options.body = this.getBody(options.body);
    options.headers = this.getHeaders(options.headers);

    let request = new globalThis.Request(fullUrl, options);

    if (this.hooks?.beforeRequest) {
      const result = await this.hooks.beforeRequest(request, options);

      if (result instanceof Request) {
        request = result;
      }

      // return a Response to avoid making an HTTP request -> mocking
      if (result instanceof Response) {
        return result;
      }
    }

    let response = await fetch(request);

    if (this.hooks?.afterResponse) {
      const modifiedResponse = await this.hooks.afterResponse(request, response.clone(), options);

      if (modifiedResponse instanceof globalThis.Response) {
        response = modifiedResponse;
      }
    }

    if (!response.ok) {
      const errorResponse = (await response.json()) as FetcherError['response'];
      let error = new FetcherError(request, errorResponse, response.status, options);
      if (this.hooks?.beforeError) {
        const newError = await this.hooks.beforeError(error);
        if (newError) {
          error = newError;
        }
      }

      throw error;
    }

    const contentType = response.headers.get('Content-Type')?.split(';')[0];
    switch (contentType) {
      case 'application/json':
        return await response.json();
      case 'text/event-stream':
        return await this.streamResponse(response);
      case 'application/octet-stream':
        return await response.arrayBuffer();
      default:
        return await response.text();
    }
  }

  private getHeaders(headers?: RequestInit['headers']) {
    const defaultHeaders: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    if (headers) {
      Object.entries(headers).forEach(([key, value]) => {
        if (value === null || value === undefined) {
          delete defaultHeaders[key];
        } else {
          defaultHeaders[key] = value;
        }
      });
    }

    return defaultHeaders;
  }

  private getBody(body: RequestInit['body']) {
    if (!body) {
      return undefined;
    }
    return body instanceof FormData || typeof body === 'string' ? body : JSON.stringify(body);
  }

  private async streamResponse(response: Response) {
    if (!response.body) {
      return '';
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let result = '';

    try {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        result += decoder.decode(value, { stream: true });
      }

      return JSON.parse(result);
    } finally {
      reader.releaseLock();
    }
  }
}
