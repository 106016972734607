import type { SearchSchemaInput } from '@tanstack/react-router';

import type { BaseSorting, SortOrderType } from '@legalfly/api/core';
import { SortOrder } from '@legalfly/api/core';

interface SortableRouteConfig<T extends string> {
  defaultSort: BaseSorting<T>;
}

export function createSortableRoute<T extends string>({ defaultSort }: SortableRouteConfig<T>) {
  return {
    validateSearch: (search: { sortBy?: T; sortOrder?: SortOrderType } & SearchSchemaInput) => {
      const sorting: BaseSorting<T> = {
        sortBy: search.sortBy ?? defaultSort.sortBy,
        sortOrder:
          search.sortOrder && SortOrder.includes(search.sortOrder)
            ? search.sortOrder
            : defaultSort.sortOrder,
      };

      return sorting;
    },
    loaderDeps: ({ search: { sortBy, sortOrder } }: { search: BaseSorting<T> }) => ({
      sortBy,
      sortOrder,
    }),
  };
}
