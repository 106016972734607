import type { BaseSorting } from '../core';

export const MULTI_REVIEW_SORT_CONFIG = {
  createdAt: 'createdAt',
  name: 'name',
} as const;

export type MultiReviewSortField = keyof typeof MULTI_REVIEW_SORT_CONFIG;
export type MappedMultiReviewSortField = (typeof MULTI_REVIEW_SORT_CONFIG)[MultiReviewSortField];

export const DEFAULT_MULTI_REVIEW_SORT: BaseSorting<MultiReviewSortField> = {
  sortBy: 'createdAt',
  sortOrder: 'DESC',
} as const;

export type DefaultMultiReviewSort = typeof DEFAULT_MULTI_REVIEW_SORT;
