import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { ReviewStatus } from '@legalfly/api/reviews';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, IconButton } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { useHighlightActiveCommentPopover } from 'components/common/comments/useHighlightActiveCommentsPopover';
import { validateCommentSearch } from 'components/common/comments/validateCommentSearch';
import { ContentRow } from 'components/common/content/ContentRow';
import { ShareDialog } from 'components/common/dialog/share/ShareDialog';
import { EditableMultiReviewName } from 'components/multi-reviews/EditableMultiReviewName';
import { ReviewsGrid } from 'components/multi-reviews/ReviewsGrid';
import { useListenForComments } from 'core/modules/comments/useListenForComments';
import {
  multiReviewsQueryOptions,
  useListenToMultiReviewStatus,
  useMultiReview,
} from 'core/modules/multi-reviews';
import { useReviewToCSV } from 'core/modules/reviews/helpers';

export const Route = createFileRoute('/_auth/_layout/multi-review/$uuid')({
  validateSearch: validateCommentSearch,
  component: MultiReviewDetailRoute,
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(
      multiReviewsQueryOptions.multiReview({ uuid: params.uuid }),
    );
  },
});

function MultiReviewDetailRoute() {
  const { t } = useTranslation();
  const { uuid } = Route.useParams();
  const searchParams = Route.useSearch();
  const navigate = useNavigate();

  const { multiReview } = useMultiReview({ uuid });
  const { reviews } = multiReview;
  const { exportReviewAsCSV, isDownloading } = useReviewToCSV(reviews, 'review.csv');

  useListenToMultiReviewStatus({ uuid });
  useListenForComments();
  useHighlightActiveCommentPopover({
    commentUuid: searchParams?.commentUuid,
    isEnabled: Boolean(
      reviews.find((review) =>
        review.items.find((item) => item.uuid === searchParams?.commentUuid),
      ),
    ),
  });

  const handleExportReviewAsCSV = () => {
    trackEvent({
      action: 'click',
      category: 'multiReview',
      label: 'downloadReport',
    });
    exportReviewAsCSV();
  };

  return (
    <div className='flex flex-col gap-3'>
      <ContentRow className='justify-between gap-2'>
        <IconButton
          name='chevron-left'
          onClick={() => navigate({ to: '/multi-review' })}
          variant='tertiary'
          size='sm'
        />
        <EditableMultiReviewName multiReview={multiReview} />
        <div className='flex items-center gap-2'>
          <ShareDialog
            title={t('dialog.share.multiReview.title')}
            description={t('dialog.share.multiReview.description')}
            entityType='multiReview'
            entityUuid={multiReview.uuid}
            readOnly={!multiReview.canEdit}
            owner={multiReview.owner}
          />
          <Button
            disabled={reviews.some((review) => review.status === ReviewStatus.RUNNING)}
            variant='soft'
            size='sm'
            onClick={handleExportReviewAsCSV}
            renderLeft={isDownloading ? <Spinner /> : <Icon name='download-1' />}
          >
            {t('documents.actionBar.downloadReport')}
          </Button>
        </div>
      </ContentRow>
      <ReviewsGrid reviews={reviews} multiReviewUuid={uuid} />
    </div>
  );
}
