import type { ComponentProps } from 'react';
import type { SortingColumn } from '@tanstack/table-core';

import { Button } from '@legalfly/ui/button';
import type { TableDataRow } from '@legalfly/ui/data-table/';
import { Icon } from '@legalfly/ui/icon';
import { cn } from '@legalfly/ui/utils';

interface SortableHeaderProps<TData extends TableDataRow> {
  column: SortingColumn<TData>;
  children: React.ReactNode;
  buttonProps?: Omit<ComponentProps<typeof Button>, 'onClick'>;
  iconProps?: ComponentProps<typeof Icon>;
  iconClassName?: string;
}

export function SortableTableHeader<TData extends TableDataRow>({
  column,
  children,
  buttonProps,
  iconProps,
  iconClassName,
}: SortableHeaderProps<TData>) {
  return (
    <Button
      variant='unstyled'
      className={cn('flex items-center gap-2', buttonProps?.className)}
      onClick={column.getToggleSortingHandler()}
      {...buttonProps}
    >
      {children}
      {column.getCanSort() && (
        <Icon
          name='arrow-up'
          className={cn(
            'transition-transform',
            column.getIsSorted() === 'desc' && 'rotate-180',
            !column.getIsSorted() && 'opacity-0',
            iconClassName,
            iconProps?.className,
          )}
          {...iconProps}
        />
      )}
    </Button>
  );
}
