import { useEffect, useRef, useState } from 'react';

import { useWindowSize } from '@legalfly/utils/hooks';

import { getCssVariable } from '../utils/getCssVariable';

export const useTableHeight = () => {
  const tableRef = useRef<HTMLTableElement>(null!);
  const size = useWindowSize();
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    if (!tableRef.current) return;

    const updateHeight = () => {
      const table = tableRef.current;
      if (!table) return;

      const rect = table.getBoundingClientRect();
      const bodyPadding = getCssVariable('body-padding');
      const contentPaddingY = getCssVariable('content-padding-y');

      const calculatedHeight = Math.max(
        0,
        Number(size.height - rect.top - bodyPadding - contentPaddingY),
      );

      setTableHeight(calculatedHeight);
    };

    const resizeObserver = new ResizeObserver(updateHeight);
    resizeObserver.observe(tableRef.current);
    updateHeight();

    return () => {
      resizeObserver.disconnect();
    };
  }, [size.height]);

  return { tableHeight, tableRef };
};
