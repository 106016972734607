import type { ComponentPropsWithRef, ElementType } from 'react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from 'utils';

const variant = {
  display: 'font-writer text-display',
  title: 'font-writer text-title',
  heading2: 'font-writer text-heading2',

  headingLight: 'text-heading-light',
  heading: 'text-heading',
  headingSemiBold: 'text-heading-semibold',

  subheadingLight: 'text-subheading-light',
  subheading: 'text-subheading',
  subheadingSemiBold: 'text-subheading-semibold',

  bodyLargeLight: 'text-body-large-light',
  bodyLarge: 'text-body-large',
  bodyLargeSemiBold: 'text-body-large-semibold',

  bodyLight: 'text-body-light',
  body: 'text-body',
  bodySemiBold: 'text-body-semibold',

  bodySmall: 'text-body-small',

  captionLight: 'text-caption-light',
  caption: 'text-caption',
  captionSemiBold: 'text-caption-semibold',
} as const;

const textVariants = Object.keys(variant) as (keyof typeof variant)[];

const chadcnTextVariants = cva('', {
  variants: {
    variant,
  },
  defaultVariants: {
    variant: 'body',
  },
});

type DistributiveOmit<T, TOmitted extends PropertyKey> = T extends any ? Omit<T, TOmitted> : never;

interface Props extends VariantProps<typeof chadcnTextVariants> {
  className?: string;
}

const Text = <TAs extends ElementType>(
  props: Props & {
    as?: TAs;
  } & DistributiveOmit<ComponentPropsWithRef<ElementType extends TAs ? 'p' : TAs>, 'as'>,
) => {
  const { as: Comp = 'p', variant, className, ...rest } = props;
  return <Comp className={cn(chadcnTextVariants({ variant, className }))} {...rest} />;
};

Text.displayName = 'Text';

export { Text, textVariants };
