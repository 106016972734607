import { queryOptions } from '@tanstack/react-query';

import type { ApiDraft, ApiTemplate, DraftingApi } from '@legalfly/api/drafting';

export const draftingQueryOptions = ({ draftingApi }: { draftingApi: DraftingApi }) => ({
  templates: (query: Parameters<DraftingApi['getDraftingTemplates']>[0] = {}) =>
    queryOptions({
      queryKey: ['drafting', 'templates', query.sortBy, query.sortOrder],
      queryFn: () => draftingApi.getDraftingTemplates(query),
    }),
  template: ({ uuid }: { uuid: ApiTemplate['uuid'] }) =>
    queryOptions({
      queryKey: ['drafting', 'template', uuid],
      queryFn: () => draftingApi.getDraftingTemplate({ uuid }),
    }),
  drafts: (query: Parameters<DraftingApi['getDrafts']>[0] = {}) =>
    queryOptions({
      queryKey: ['drafting', 'drafts', query.sortBy, query.sortOrder],
      queryFn: () => draftingApi.getDrafts(query),
    }),
  draft: ({ uuid }: { uuid: ApiDraft['uuid'] }) =>
    queryOptions({
      queryKey: ['drafting', 'draft', uuid],
      queryFn: () => draftingApi.getDraft({ uuid }),
    }),
});
