import type { ApiPlaybook } from '@legalfly/api/playbooks';
import { Button, IconButton } from '@legalfly/ui/button';
import { Checkbox } from '@legalfly/ui/checkbox';
import { Text } from '@legalfly/ui/text';

import { PlaybookItemsSheet } from './PlaybookItemsSheet';

interface Props {
  playbook: ApiPlaybook;
  checked: boolean;
  sheetTopOffset?: string;
  onCheckedChange: (checked: boolean) => void;
  onRemove: () => void;
}

export const PlaybookListItem = ({
  playbook,
  checked,
  sheetTopOffset = '0',
  onCheckedChange,
  onRemove,
}: Props) => {
  return (
    <Button asChild className='size-auto select-none gap-3 px-4 py-2'>
      <label>
        <Checkbox checked={checked} onCheckedChange={onCheckedChange} />
        <Text className='flex-1'>{playbook.name}</Text>
        <PlaybookItemsSheet playbook={playbook} topOffset={sheetTopOffset} />
        <IconButton name='x-close' onClick={onRemove} variant='tertiary' size='xs' />
      </label>
    </Button>
  );
};
