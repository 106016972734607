import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import type { ApiDocument } from '@legalfly/api/documents';

interface SelectedDocumentsContextType {
  selectedDocuments: ApiDocument[];
  toggleSelectedDocument: (document: ApiDocument) => void;
  addSelectedDocument: (document: ApiDocument) => void;
  addSelectedDocuments: (documents: ApiDocument[]) => void;
  clearSelectedDocuments: () => void;
  setDocuments: (documents: ApiDocument[]) => void;
  updateDocument: (document: ApiDocument) => void;
}

const SelectedDocumentsContext = createContext<SelectedDocumentsContextType | undefined>(undefined);

export const SelectedDocumentsProvider = ({ children }: PropsWithChildren) => {
  const [documents, setDocuments] = useState<ApiDocument[]>([]);

  const toggleSelectedDocument = useCallback((document: ApiDocument) => {
    setDocuments((prevDocuments) => {
      const index = prevDocuments.findIndex((doc) => doc.uuid === document.uuid);
      if (index !== -1) {
        return prevDocuments.filter((doc) => doc.uuid !== document.uuid);
      } else {
        return [...prevDocuments, document];
      }
    });
  }, []);

  const addSelectedDocument = useCallback((document: ApiDocument) => {
    setDocuments((prevDocuments) => [...prevDocuments, document]);
  }, []);

  const clearSelectedDocuments = useCallback(() => {
    setDocuments([]);
  }, []);

  const addSelectedDocuments = useCallback((documents: ApiDocument[]) => {
    setDocuments((prevDocuments) => [...prevDocuments, ...documents]);
  }, []);

  const updateDocument = useCallback((document: ApiDocument) => {
    setDocuments((prevDocuments) =>
      prevDocuments.map((doc) => (doc.uuid === document.uuid ? document : doc)),
    );
  }, []);

  const value = useMemo(
    () => ({
      selectedDocuments: documents,
      toggleSelectedDocument,
      addSelectedDocument,
      addSelectedDocuments,
      clearSelectedDocuments,
      setDocuments,
      updateDocument,
    }),
    [
      documents,
      toggleSelectedDocument,
      addSelectedDocument,
      addSelectedDocuments,
      clearSelectedDocuments,
      setDocuments,
      updateDocument,
    ],
  );

  return (
    <SelectedDocumentsContext.Provider value={value}>{children}</SelectedDocumentsContext.Provider>
  );
};

export const useSelectedDocuments = () => {
  const context = useContext(SelectedDocumentsContext);
  if (context === undefined) {
    throw new Error('useSelectedDocuments must be used within a SelectedDocumentsContext');
  }
  return context;
};
