import { createFileRoute } from '@tanstack/react-router';

import { TranslateTextBox } from 'components/translate/TranslateTextBox';
import { routeRedirectWithFeatureFlag } from 'core/analytics';

export const Route = createFileRoute('/_auth/_layout/translate/_layout/text/')({
  beforeLoad: async ({ context }) =>
    routeRedirectWithFeatureFlag(context.queryClient, 'translate-text-agent'),
  component: TranslateTextRoute,
});

function TranslateTextRoute() {
  return <TranslateTextBox />;
}
