import { useCallback, useLayoutEffect, useState } from 'react';

import { useDebounceCallback } from './useDebounceCallback';

interface WindowSize {
  width: number;
  height: number;
}

const createDimensionsHook =
  (getSize: () => WindowSize) =>
  ({ delay = 50 }: { delay?: number } = {}) => {
    const [windowSize, setWindowSize] = useState<WindowSize>(getSize);

    const debouncedSetWindowSize = useDebounceCallback(setWindowSize, delay);

    const handleSize = useCallback(() => {
      const setSize = delay ? debouncedSetWindowSize : setWindowSize;

      setSize(getSize());
    }, [delay, debouncedSetWindowSize, setWindowSize]);

    useLayoutEffect(() => {
      window.addEventListener('resize', handleSize);

      return () => window.removeEventListener('resize', handleSize);
    }, [handleSize]);

    return windowSize;
  };

export const useWindowSize = createDimensionsHook(() => ({
  width: window.innerWidth,
  height: window.innerHeight,
}));

export const useScreenSize = createDimensionsHook(() => ({
  width: window.screen.width,
  height: window.screen.height,
}));
