import { queryOptions } from '@tanstack/react-query';

import type { AnonymizationApi, ApiAnonymization } from '@legalfly/api/anonymization';

export const anonymizationQueryOptions = ({
  anonymizationApi,
}: {
  anonymizationApi: AnonymizationApi;
}) => ({
  anonymizations: (query: Parameters<AnonymizationApi['getAnonymizations']>[0] = {}) =>
    queryOptions({
      queryKey: ['anonymizations', query.sortBy, query.sortOrder],
      queryFn: () => anonymizationApi.getAnonymizations(query),
    }),
  anonymization: ({ uuid }: { uuid: ApiAnonymization['uuid'] }) =>
    queryOptions({
      queryKey: ['anonymization', uuid],
      queryFn: () => anonymizationApi.getAnonymization({ uuid }),
    }),
});
