import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { usePrevious } from 'common/hooks/usePrevious';

import { ClauseTypeEnum } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, IconButton } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { useWebSocketSubscriber } from '@legalfly/websockets';
import { useHighlightActiveCommentPopover } from 'components/common/comments/useHighlightActiveCommentsPopover';
import { validateCommentSearch } from 'components/common/comments/validateCommentSearch';
import { ContentRow } from 'components/common/content/ContentRow';
import { ShareDialog } from 'components/common/dialog/share/ShareDialog';
import { DraftingHeader } from 'components/drafting/DraftingHeader';
import { DraftClausesList } from 'components/drafting/drafts/DraftClausesList';
import { DraftCompletedDialog } from 'components/drafting/drafts/DraftCompletedDialog';
import { DraftConversation } from 'components/drafting/drafts/DraftConversation';
import { EditableDraftName } from 'components/drafting/EditableDraftName';
import { useListenForComments } from 'core/modules/comments/useListenForComments';
import {
  draftingQueryOptions,
  useDownloadDraftAsDocx,
  useDownloadDraftAsPdf,
  useDraft,
  useUpdateDraftClauseCache,
} from 'core/modules/drafting';

export const Route = createFileRoute('/_auth/_layout/drafting/drafts/$uuid')({
  validateSearch: validateCommentSearch,
  component: DraftingDraftRoute,
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(draftingQueryOptions.draft({ uuid: params.uuid }));
  },
});

function DraftingDraftRoute() {
  const { t } = useTranslation();
  const { uuid } = Route.useParams();
  const searchParams = Route.useSearch();
  const navigate = useNavigate();

  const [isCompletedDialogOpen, setIsCompletedDialogOpen] = useState(false);
  const { downloadPdf, isLoading: isDownloadingPdf } = useDownloadDraftAsPdf();
  const { downloadDocx, isLoading: isDownloadingDocx } = useDownloadDraftAsDocx();

  const { draft } = useDraft({ uuid });
  const updateDraftClauseCache = useUpdateDraftClauseCache();

  const nextClauseToDraft = draft.clauses.find((clause) => clause.status === 'progress');

  useWebSocketSubscriber(`draft_clause_${draft.uuid}`, (data) => {
    updateDraftClauseCache({
      draftUuid: draft.uuid,
      updatedClause: {
        ...data.clause,
        type: ClauseTypeEnum.DRAFT,
      },
    });
  });

  useListenForComments();
  useHighlightActiveCommentPopover({
    commentUuid: searchParams?.commentUuid,
    isEnabled: Boolean(draft.clauses.find((clause) => clause.uuid === searchParams?.commentUuid)),
  });

  const prevNextClauseToDraft = usePrevious(nextClauseToDraft);
  useEffect(() => {
    if (prevNextClauseToDraft && !nextClauseToDraft) {
      setIsCompletedDialogOpen(true);
    }
  }, [prevNextClauseToDraft, nextClauseToDraft]);

  const handleDownloadPdf = () => {
    trackEvent({
      action: 'click',
      category: 'drafts',
      label: 'downloadPdf',
    });
    downloadPdf({ uuid });
  };

  const handleDownloadDocx = () => {
    trackEvent({
      action: 'click',
      category: 'drafts',
      label: 'downloadDocx',
    });
    downloadDocx({ uuid });
  };

  return (
    <div className='flex gap-3'>
      <div className='flex h-content flex-2 flex-col gap-3'>
        <ContentRow className='justify-between'>
          <div className='flex items-center gap-2'>
            <IconButton
              name='chevron-left'
              onClick={() => navigate({ to: '/drafting/drafts' })}
              variant='tertiary'
              size='sm'
            />
            <EditableDraftName draft={draft} />
          </div>
          {!nextClauseToDraft && (
            <div className='flex items-center gap-4'>
              <ShareDialog
                title={t('dialog.share.draft.title')}
                description={t('dialog.share.draft.description')}
                entityType='draft'
                entityUuid={draft.uuid}
                readOnly={!draft.canEdit}
                owner={draft.owner}
              />

              {draft.canEdit && (
                <>
                  <Button
                    size='sm'
                    variant='soft'
                    renderLeft={<Icon name='download-2' />}
                    onClick={handleDownloadPdf}
                    isLoading={isDownloadingPdf}
                  >
                    PDF
                  </Button>
                  <Button
                    size='sm'
                    variant='soft'
                    renderLeft={<Icon name='download-2' />}
                    onClick={handleDownloadDocx}
                    isLoading={isDownloadingDocx}
                  >
                    Word
                  </Button>
                </>
              )}
            </div>
          )}
        </ContentRow>
        <div className='flex h-content flex-col overflow-auto bg-fill-maximal px-content-x py-content-y'>
          <DraftingHeader title={draft.title} createdAt={draft.createdAt} />
          <DraftClausesList draft={draft} nextClauseToDraft={nextClauseToDraft} />
        </div>
      </div>
      {draft.canEdit && (
        <div className='relative h-content flex-1 overflow-auto bg-fill-maximal px-6 py-6'>
          <DraftConversation draft={draft} nextClauseToDraft={nextClauseToDraft} />
        </div>
      )}
      <DraftCompletedDialog
        isOpen={isCompletedDialogOpen}
        onOpenChange={setIsCompletedDialogOpen}
        draftUuid={draft.uuid}
      />
    </div>
  );
}
