import type { BaseSorting } from '../core/types';

export const ANONYMIZATION_SORT_CONFIG = {
  createdAt: 'createdAt',
  name: 'name',
} as const;

export type AnonymizationSortField = keyof typeof ANONYMIZATION_SORT_CONFIG;
export type MappedAnonymizationSortField =
  (typeof ANONYMIZATION_SORT_CONFIG)[AnonymizationSortField];

export const DEFAULT_ANONYMIZATION_SORT: BaseSorting<AnonymizationSortField> = {
  sortBy: 'createdAt',
  sortOrder: 'DESC',
} as const;

export type DefaultAnonymizationSort = typeof DEFAULT_ANONYMIZATION_SORT;
