export enum Jurisdiction {
  NONE = 'none',
  BELGIUM = 'belgium',
  FRANCE = 'france',
  GERMANY = 'germany',
  NETHERLANDS = 'netherlands',
  SWITZERLAND = 'switzerland',
  UNITED_KINGDOM = 'united_kingdom',
  UNITED_STATES = 'united_states',
  CANADA = 'canada',
  LUXEMBOURG = 'luxembourg',
  AUSTRIA = 'austria',
  CZECHIA = 'czechia',
  DENMARK = 'denmark',
  ESTONIA = 'estonia',
  FINLAND = 'finland',
  GREECE = 'greece',
  HUNGARY = 'hungary',
  IRELAND = 'ireland',
  ITALY = 'italy',
  POLAND = 'poland',
  PORTUGAL = 'portugal',
  SPAIN = 'spain',
  SWEDEN = 'sweden',
  SAUDI_ARABIA = 'saudi_arabia',
}

const JurisdictionToCountryCode = {
  [Jurisdiction.NONE]: '',
  [Jurisdiction.BELGIUM]: 'BE',
  [Jurisdiction.FRANCE]: 'FR',
  [Jurisdiction.GERMANY]: 'DE',
  [Jurisdiction.NETHERLANDS]: 'NL',
  [Jurisdiction.SWITZERLAND]: 'CH',
  [Jurisdiction.UNITED_KINGDOM]: 'UK',
  [Jurisdiction.UNITED_STATES]: 'US',
  [Jurisdiction.CANADA]: 'CA',
  [Jurisdiction.LUXEMBOURG]: 'LU',
  [Jurisdiction.AUSTRIA]: 'AT',
  [Jurisdiction.CZECHIA]: 'CZ',
  [Jurisdiction.DENMARK]: 'DK',
  [Jurisdiction.ESTONIA]: 'EE',
  [Jurisdiction.FINLAND]: 'FI',
  [Jurisdiction.GREECE]: 'GR',
  [Jurisdiction.HUNGARY]: 'HU',
  [Jurisdiction.IRELAND]: 'IE',
  [Jurisdiction.ITALY]: 'IT',
  [Jurisdiction.POLAND]: 'PL',
  [Jurisdiction.PORTUGAL]: 'PT',
  [Jurisdiction.SPAIN]: 'ES',
  [Jurisdiction.SWEDEN]: 'SE',
  [Jurisdiction.SAUDI_ARABIA]: 'SA',
} as const;

export const mapJurisdictionToCountryCode = (jurisdiction: Jurisdiction) =>
  JurisdictionToCountryCode[jurisdiction] ?? '';

export const featureFlags = [
  'custom-agents',
  'archive',
  'collaboration-p1',
  'collaboration-p2',
  'translate-text-agent',
  'translate-file-agent',
  'sharepoint',
  'office-reports',
] as const;
export type FeatureFlagType = (typeof featureFlags)[number];

export const SortOrder = ['ASC', 'DESC'] as const;
export type SortOrderType = (typeof SortOrder)[number];

export interface BaseSorting<TSortField extends string> {
  sortBy: TSortField;
  sortOrder: SortOrderType;
}
