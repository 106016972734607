import { createFileRoute } from '@tanstack/react-router';

import { TranslateBox } from 'components/translate/TranslateBox';
import { TranslateGroupProgress } from 'components/translate/TranslateGroupProgress';
import { TranslateGroupResult } from 'components/translate/TranslateGroupResult';
import { routeRedirectWithFeatureFlag } from 'core/analytics';
import {
  TranslateGroupListener,
  translateQueryOptions,
  useTranslateGroup,
} from 'core/modules/translate';

export const Route = createFileRoute('/_auth/_layout/translate/_layout/files/group/$uuid')({
  beforeLoad: async ({ context }) =>
    routeRedirectWithFeatureFlag(context.queryClient, 'translate-file-agent'),
  component: TranslateGroupRoute,
  loader: ({ context, params }) =>
    context.queryClient.ensureQueryData(translateQueryOptions.translateGroup(params.uuid)),
});

function TranslateGroupRoute() {
  const { uuid } = Route.useParams();
  const { translateGroup } = useTranslateGroup(uuid);

  const inProgress = translateGroup.files.some(
    (file) => file.status === 'in_progress' || file.status === 'pending',
  );
  const completed = translateGroup.files.every(
    (file) => file.status === 'completed' || file.status === 'error',
  );

  return (
    <TranslateBox>
      <TranslateGroupListener groupUuid={uuid} files={translateGroup.files} />
      {inProgress && <TranslateGroupProgress translateGroup={translateGroup} />}
      {completed && <TranslateGroupResult translateGroup={translateGroup} />}
    </TranslateBox>
  );
}
