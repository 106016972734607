import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from 'utils';

const Tooltip = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipProvider = TooltipPrimitive.TooltipProvider;
const TooltipPortal = TooltipPrimitive.Portal;

interface TooltipContentProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  TooltipContentProps
>(({ className, sideOffset = 4, style, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 whitespace-normal break-words bg-fill-stronger px-3 py-1.5 light:bg-fill-strong',
      'animate-in fade-in-0 zoom-in-50',
      'data-[state=closed]:animate-out data-[state=closed]:fade-out-95 data-[state=closed]:zoom-out-95',
      'data-[side=bottom]:slide-in-from-top-2',
      'data-[side=left]:slide-in-from-left-2',
      'data-[side=right]:slide-in-from-right-2',
      'data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    style={{
      ...style,
      maxHeight: 'var(--radix-tooltip-content-available-height)',
    }}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const TooltipArrow = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Arrow>
>(({ className, ...props }, ref) => (
  <TooltipPrimitive.Arrow
    ref={ref}
    className={cn(
      'fill-fill-stronger light:fill-fill-strong',
      'animate-in fade-in-0 zoom-in-50',
      'data-[state=closed]:animate-out data-[state=closed]:fade-out-95 data-[state=closed]:zoom-out-95',
      'data-[side=bottom]:slide-in-from-top-2',
      'data-[side=left]:slide-in-from-left-2',
      'data-[side=right]:slide-in-from-right-2',
      'data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  />
));
TooltipArrow.displayName = TooltipPrimitive.Arrow.displayName;

export { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger, TooltipArrow, TooltipPortal };
