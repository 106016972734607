import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast } from '@legalfly/ui/toast';

export const downloadBlobToDesktop = (blob: Blob, fileName: string) => {
  const downloadUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(downloadUrl);
};

const fetchAndProcessFile = async (
  url: string,
  signal: AbortSignal,
  setProgress: (progress: number) => void,
) => {
  const response = await fetch(url, { signal });
  if (!response.ok) throw new Error(`Failed to download file ${url}`);

  const reader = response.body?.getReader();
  const contentLength = Number(response.headers.get('content-length'));
  const contentType = response.headers.get('content-type');

  let loaded = 0;
  const chunks: Uint8Array[] = [];

  while (true) {
    const { done, value } = (await reader?.read()) ?? {};
    if (done) break;
    chunks.push(value!);
    loaded += value!.length;
    setProgress(Math.round((loaded / contentLength) * 100));
  }

  return new Blob(chunks, { type: contentType ?? 'application/octet-stream' });
};

export const useDownload = () => {
  const [downloadAbortController, setDownloadAbortController] = useState(
    () => new AbortController(),
  );

  const { toast } = useToast();
  const { t } = useTranslation();

  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);

  const showStartedDownloadToast = () => {
    toast({
      title: t('config.notifications.DOCUMENT_DOWNLOAD.started.title'),
      description: t('config.notifications.DOCUMENT_DOWNLOAD.started.description'),
      variant: 'success',
    });
  };

  const showErrorToast = () => {
    toast({
      title: t('config.notifications.DOCUMENT_DOWNLOAD.error.title'),
      description: t('config.notifications.DOCUMENT_DOWNLOAD.error.description'),
      variant: 'danger',
    });
  };

  const performDownload = async (url: string, fileName: string) => {
    if (downloadAbortController.signal.aborted) {
      setDownloadAbortController(new AbortController());
      return;
    }

    try {
      setIsDownloading(true);
      const blob = await fetchAndProcessFile(url, downloadAbortController.signal, setProgress);
      downloadBlobToDesktop(blob, fileName);
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error.name !== 'AbortError') {
          showErrorToast();
        } else {
          setDownloadAbortController(new AbortController());
        }
      }
      throw error;
    } finally {
      setIsDownloading(false);
    }
  };

  const cancelDownload = () => {
    downloadAbortController.abort();
    setIsDownloading(false);
    setProgress(0);
  };

  return {
    progress,
    isDownloading,
    performDownload,
    setProgress,
    setIsDownloading,
    cancelDownload,
    showStartedDownloadToast,
    showErrorToast,
  };
};
