import { useQueryClient } from '@tanstack/react-query';

import type { ApiDocument } from '@legalfly/api/documents';
import { createDocumentsModule } from '@legalfly/modules/documents';
import type { WebSocketDataType } from '@legalfly/websockets';
import { useWebSocketSubscriber } from '@legalfly/websockets';

import { documentsApi } from '../../di';

const documentsModule = createDocumentsModule({
  documentsApi,
});

export const {
  useFolder,
  useFile,
  useFiles,
  useFileSnippets,
  useFileSummary,
  useCreateFile,
  useCreateFolder,
  useDeleteFile,
  useDeleteFolder,
  useCreateDocumentEntity,
  useUpdateDocumentEntity,
  useDeleteDocumentEntity,
  useDownloadDocumentUrl,
  useDownloadAnonymizedDocumentUrlViaWS,
  useDropDocuments,
  useUpdateFolder,
  useRetryFile,
  useCountFolderDocuments,
  useDeleteDocuments,
  useCreateDocumentsZip,
  documentsQueryOptions,
} = documentsModule;

export * from './toasts';

export const useListenToDocumentUploadStatus = (
  {
    document,
  }: {
    document: ApiDocument;
  },
  callback?: (data: WebSocketDataType<`document_upload_status_string`>) => void,
) => {
  const queryClient = useQueryClient();

  useWebSocketSubscriber(`document_upload_status_${document.uuid}`, (data) => {
    queryClient.setQueryData(documentsQueryOptions.file(document.uuid).queryKey, (oldData) => {
      if (!oldData) return oldData;
      return { ...oldData, status: data.status };
    });

    queryClient.setQueryData(
      documentsQueryOptions.folder({ uuid: document.parent?.uuid ?? undefined }).queryKey,
      (oldData) => {
        if (!oldData) return oldData;
        const updatedDocuments = oldData.documents.map((doc) =>
          doc.uuid === document.uuid ? { ...doc, status: data.status } : doc,
        );
        return { ...oldData, documents: updatedDocuments };
      },
    );

    callback?.(data);
  });
};

const ListenToDocumentUploadStatus = ({ document }: { document: ApiDocument }) => {
  useListenToDocumentUploadStatus({ document });
  return null;
};

export const ListenToDocumentsUploadStatus = ({ documents }: { documents: ApiDocument[] }) =>
  documents.map((document) => (
    <ListenToDocumentUploadStatus key={document.uuid} document={document} />
  ));
