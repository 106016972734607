import type * as React from 'react';
import type { AvatarImageProps } from '@radix-ui/react-avatar';

import { Avatar, AvatarFallback, AvatarImage } from '@legalfly/ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@legalfly/ui/tooltip';
import { cn } from '@legalfly/ui/utils';

import { AvatarStatus } from './AvatarStatus';

const getFirstLetter = (value: string) => value?.[0] ?? '';

interface Props extends React.ComponentProps<typeof Avatar> {
  avatarUrl: AvatarImageProps['src'] | null;
  firstName: string | undefined;
  lastName: string | undefined;
  lastActivity?: Date | null;
}

export const UserAvatar = ({
  avatarUrl,
  firstName,
  lastName,
  lastActivity,
  className,
  ...props
}: Props) => {
  const fullName = `${firstName ?? ''} ${lastName ?? ''}`.trim();

  const avatar = (
    <Avatar {...props} className={cn('relative overflow-visible', className)}>
      {avatarUrl && <AvatarImage src={avatarUrl} alt={fullName} />}
      <AvatarFallback>
        {firstName && lastName ? `${getFirstLetter(firstName)}${getFirstLetter(lastName)}` : 'LF'}
      </AvatarFallback>
      {lastActivity && <AvatarStatus lastActivity={lastActivity} />}
    </Avatar>
  );

  if (!fullName) return avatar;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{avatar}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side='bottom'>
            <span className='text-caption'>{fullName}</span>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
};
