import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import type { ApiReviewItem } from '@legalfly/api/reviews';

import type { multiReviewsQueryOptions } from './multiReviewsQueryOptions';

export const initMultiReviewHelpers =
  (multiReviewsOptions: ReturnType<typeof multiReviewsQueryOptions>) => () => {
    const queryClient = useQueryClient();

    const adjustMultiReviewItem = useCallback(
      (
        data: { parentUuid: string | null; entityUuid: string },
        cb: (item: ApiReviewItem) => ApiReviewItem,
      ) => {
        if (!data.parentUuid) return;

        queryClient.setQueryData(
          multiReviewsOptions.multiReview({ uuid: data.parentUuid }).queryKey,
          (multiReview) => {
            if (!multiReview) return multiReview;
            return {
              ...multiReview,
              reviews: multiReview.reviews.map((review) => ({
                ...review,
                items: review.items.map((item) => {
                  if (item.uuid === data.entityUuid) {
                    return cb(item);
                  }
                  return item;
                }),
              })),
            };
          },
        );
      },
      [queryClient],
    );

    return {
      adjustMultiReviewItem,
    };
  };
