/* eslint-disable jsx-a11y/label-has-associated-control */

import { useTranslation } from 'react-i18next';
import type { CellContext, Table } from '@tanstack/react-table';

import { type ApiDocument } from '@legalfly/api/documents';
import { Checkbox } from '@legalfly/ui/checkbox';
import { cn } from '@legalfly/ui/utils';

interface HeaderProps {
  allowMultiple: boolean;
  table: Table<ApiDocument>;
}

const Header = ({ allowMultiple, table }: HeaderProps) => {
  const { t } = useTranslation('components');

  if (!allowMultiple) {
    return null;
  }

  return (
    <label className='flex cursor-pointer items-center gap-2 p-4'>
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label={t('documents.documentPicker.selectAll')}
      />
    </label>
  );
};

const Cell = ({ row }: CellContext<ApiDocument, unknown>) => {
  const { t } = useTranslation('components');

  const disabled = !row.getCanSelect();

  return (
    <label className={cn('p-4', disabled ? 'pointer-events-none' : 'cursor-pointer')}>
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label={t('documents.documentPicker.selectRow')}
        disabled={disabled}
      />
    </label>
  );
};

export const SelectColumn = {
  Header,
  Cell,
};
