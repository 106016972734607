import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import type { ApiReviewItem } from '@legalfly/api/reviews';

import type { reviewsQueryOptions } from './reviewsQueryOptions';

export const initReviewHelpers = (reviewsOptions: ReturnType<typeof reviewsQueryOptions>) => () => {
  const queryClient = useQueryClient();

  const adjustReviewPlaybookItem = useCallback(
    (
      data: { parentUuid: string | null; entityUuid: string },
      cb: (item: ApiReviewItem) => ApiReviewItem,
    ) => {
      if (!data.parentUuid) return;

      queryClient.setQueryData(
        reviewsOptions.review({ uuid: data.parentUuid }).queryKey,
        (review) => {
          if (!review) return review;
          return {
            ...review,
            items: review.items.map((item) => {
              if (item.uuid === data.entityUuid) {
                return cb(item);
              }
              return item;
            }),
          };
        },
      );
    },
    [queryClient],
  );

  return {
    adjustReviewPlaybookItem,
  };
};
