import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf } from '../../client';
import { createClient } from '../../client';
import type { Fetcher } from '../../fetcher/Fetcher';

export const createSharePointApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const signIn = () => {
    return client(api.integrations.sharePoint.auth.signIn, { method: 'POST' });
  };

  const disconnect = () => {
    return client(api.integrations.sharePoint.auth.disconnect, { method: 'POST' });
  };

  const exchangeCode = (body: BodyOf<'integrations/sharepoint/auth/exchange-code/post'>) => {
    return client(api.integrations.sharePoint.auth.exchangeCode, { method: 'POST', body });
  };

  const getSites = () => {
    return client(api.integrations.sharePoint.sites, { method: 'GET' });
  };

  return {
    signIn,
    exchangeCode,
    disconnect,
    getSites,
  };
};

export type SharePointApi = ReturnType<typeof createSharePointApi>;
