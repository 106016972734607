import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { WebSocketError } from '@legalfly/api/websocket';
import { legalFlyConfig } from '@legalfly/config';
import { reportError, reportMessage } from '@legalfly/reporting/tracing';
import { tryCatch } from '@legalfly/utils';

import { WebSocketManagerInstance } from './WebSocketManager';

const BASE_URL = `${legalFlyConfig.websocket.protocol}://${legalFlyConfig.websocket.hostname}:${legalFlyConfig.websocket.port}`;
const MAX_RETRY_COUNT = 5;
const NORMAL_CLOSURE_CODE = 1000;
const RETRY_DELAY_MULTIPLIER = 1000;

const verbose = false;
const logger = verbose ? console.log : () => {};
const WebSocketContext = createContext<boolean | null>(null);

export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext);
  if (context === null) {
    throw new Error('useWebSocketContext must be used within a WebSocketProvider');
  }
  return context;
};

interface Props {
  isAuthenticated: boolean;
  pollingEnabled: boolean;
  authenticateCallback: () => Promise<string>;
  togglePollingEnabled: (pollingEnabled: boolean) => void;
  children?: React.ReactNode;
}

export const WebSocketProvider = ({
  isAuthenticated,
  pollingEnabled,
  authenticateCallback,
  togglePollingEnabled,
  children,
}: Props) => {
  const [isConnected, setIsConnected] = useState(false);

  const handleRetry = useCallback(
    (message: string, retryCount: number) => {
      setIsConnected(false);
      if (retryCount < MAX_RETRY_COUNT) {
        // 1s → 2s → 4s → 8s → 16s
        const delay = RETRY_DELAY_MULTIPLIER * Math.pow(2, retryCount);
        logger(
          `${message}. Retrying connection in ${delay}ms (${retryCount + 1}/${MAX_RETRY_COUNT})`,
        );

        setTimeout(() => connectWebSocket(retryCount + 1), delay);
      } else {
        const msg = 'Switching to polling system after 5 failed attempts';
        reportMessage(msg);
        logger(msg);
        togglePollingEnabled(true);

        setTimeout(() => {
          logger('Retrying ws connection after 30s while polling is enabled');
          togglePollingEnabled(false);
        }, 30_000);
      }
    },
    [togglePollingEnabled],
  );

  const connectWebSocket = useCallback(
    async (retryCount: number) => {
      const [error, token] = await tryCatch(authenticateCallback());

      if (error) {
        handleRetry('Could not fetch ws token', retryCount);
        return null;
      }

      const socket = new WebSocket(`${BASE_URL}/${token}`);

      socket.onopen = () => {
        retryCount = 0;
        logger('WebSocket connection established successfully');
        setIsConnected(true);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data.toString());
        WebSocketManagerInstance.publish(data.type, data.data);
      };

      socket.onclose = (event) => {
        logger('WebSocket connection closed', event);

        if (event.code === NORMAL_CLOSURE_CODE) {
          return;
        }

        handleRetry('WebSocket connection closed unexpectedly', retryCount);

        if (retryCount === 5) {
          reportError(
            new WebSocketError(
              'WebSocket connection closed unexpectedly',
              String(event.code),
              event.reason,
              BASE_URL,
            ),
          );
        }
      };

      return socket;
    },
    [authenticateCallback, handleRetry],
  );

  useEffect(() => {
    if (!isAuthenticated || pollingEnabled) return;

    let socket: WebSocket | null = null;

    const initializeWebSocket = async () => {
      socket = await connectWebSocket(0);
    };

    initializeWebSocket();

    return () => {
      if (socket) {
        socket.close(NORMAL_CLOSURE_CODE, 'Normal closure');
      }
    };
  }, [isAuthenticated, pollingEnabled, connectWebSocket]);

  return <WebSocketContext.Provider value={isConnected}>{children}</WebSocketContext.Provider>;
};
