import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import type { ApiDraftClause } from '@legalfly/api/drafting';

import type { draftingQueryOptions } from './draftingQueryOptions';

export const initDraftingHelpers =
  (draftingOptions: ReturnType<typeof draftingQueryOptions>) => () => {
    const queryClient = useQueryClient();

    const adjustDraftClause = useCallback(
      (
        data: { parentUuid: string | null; entityUuid: string },
        cb: (clause: ApiDraftClause) => ApiDraftClause,
      ) => {
        if (!data.parentUuid) return;

        queryClient.setQueryData(
          draftingOptions.draft({ uuid: data.parentUuid }).queryKey,
          (draft) => {
            if (!draft) return draft;
            return {
              ...draft,
              clauses: draft.clauses.map((clause) => {
                if (clause.uuid === data.entityUuid) {
                  return cb(clause);
                }
                return clause;
              }),
            };
          },
        );
      },
      [queryClient],
    );

    return {
      adjustDraftClause,
    };
  };
