import { createFileRoute } from '@tanstack/react-router';
import { createSortableRoute } from 'common/utils/routeUtils';

import { DEFAULT_DOCUMENT_SORT, type DocumentSortField } from '@legalfly/api/documents';
import { withSelectedDocuments } from '@legalfly/components/documentPicker/withSelectedDocuments';
import { documentsQueryOptions } from 'core/modules/documents';

import { DocumentsFolderRoute } from './index';

const { validateSearch, loaderDeps } = createSortableRoute<DocumentSortField>({
  defaultSort: DEFAULT_DOCUMENT_SORT,
});

export const Route = createFileRoute('/_auth/_layout/documents/folder/$uuid')({
  validateSearch,
  loaderDeps,
  loader: ({ context, params, deps: { sortBy, sortOrder } }) =>
    context.queryClient.ensureQueryData(
      documentsQueryOptions.folder({ uuid: params.uuid, query: { sortBy, sortOrder } }),
    ),
  component: withSelectedDocuments(DocumentsNestedFolderRoute),
});

function DocumentsNestedFolderRoute() {
  const { uuid } = Route.useParams();
  const sorting = Route.useSearch();

  return <DocumentsFolderRoute uuid={uuid} sorting={sorting} />;
}
